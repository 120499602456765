import React from 'react';
import PendingItemCard from './PendingItemCard';
import './StatusCardStyle.css'

const StatusDisplay = ({ statusCount }) => {

    const statuses = [
        { label: 'Draft', count: statusCount?.draft, color: '#FFCDD2', status: `Draft` },
        { label: 'Waiting for Review', count: statusCount?.submitted, color: '#FFECB3', status: `Submitted` },
        { label: 'Waiting for Verification', count: statusCount?.reviewed, color: '#C8E6C9', status: `Reviewed` },
        { label: 'Hold', count: statusCount?.hold, color: '#FFCDD2', status: `Hold` },
        { label: 'Waiting for Approval', count: statusCount?.verified, color: '#BBDEFB', status: `Verified` },
    ];

    return (
        // <div className="box-container">

        <div style={{ display: 'flex', gap: 5, width: '100%' }}>
            {/* isBtnShow is changed to true by Evangelin ,Reason : to access the view forms */}
            {statuses.map((status, index) => (
                <div
                    key={index}
                // className="status-card"
                >
                    <PendingItemCard label={status.label} count={status.count} status={status?.status}  isBtnShow={true}  />
                </div>
            ))}
        </div>

        // </div>
    );
};

export default StatusDisplay;
