import React, { useEffect, useState } from 'react'
import './userProfileStyle.css'
import { Avatar, IconButton } from '@mui/material'
import { FaCamera } from "react-icons/fa";
import { MdModeEditOutline } from "react-icons/md";
import { RiLockPasswordFill } from "react-icons/ri";
import CustomModal from '../../../../../../components/custom_modal/CustomModal';
import ChangePassword from '../../../../user_management/user/change_passoword/ChangePassword';
import { LocalStorageServices } from '../../../../../../services/localStorageServices';
import { user_details_by_user_id, user_details_update_by_user_id } from '../../../../../../api/userApis';
import { useFormik } from 'formik';
import * as Yup from "yup";
import CustomTextField from '../../../../../../components/custom_text_field/CustomTextField';
import { ErrorToast, SuccessToast } from '../../../../../../toast/toasts';
function UserProfile({ is_edit, handle_modal_change }) {
    const [userDetails, setUserDetails] = useState(null)

    const [validFile, setIValidFile] = useState(true)


    useEffect(() => {
        const user_details = LocalStorageServices.getUserDetailsLocalStorageData()
        if (user_details) {
            const { UD_id } = JSON.parse(user_details)
            if (UD_id) {
                get_user_details_by_id(UD_id)
            }
        }
    }, [])

    const get_user_details_by_id = (UD_id) => {
        user_details_by_user_id(UD_id).then((response) => {
            setUserDetails(response?.data?.data)
            formik.setValues({
                ...formik.values,
                "name": response?.data?.data.UD_name ? response?.data?.data.UD_name : "",
                "mobile": response?.data?.data.UD_mobile ? response?.data?.data.UD_mobile : "",
            });
        }).catch((error) => {
            console.log(error)
        })
    }
    const handleFileUpload = (e) => {
        const file = e.target.files[0];

        if (file) {
            // if (file?.type === "image/png" || file?.type === "image/jpeg") { //ONLY ACCEPT PNG OR JPEG FORMAT
            // setIValidFile(true)
            setUserDetails({ ...userDetails, ['profile_image_url']: URL.createObjectURL(file) })
            formik.setValues({ ...formik.values, "profile_image": file ? file : "", });
        }
        // else {
        //     setIValidFile(false)
        // }

    };
    function openFileUploader() {
        document.getElementById("profile_pic_selector").click();
    }
    const formik = useFormik({
        initialValues: {
            "name": "",
            "mobile": "",
            'profile_image': ""
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Name is required"),
        }),
        onSubmit: (values) => {
            // if (validFile) {
            //VALIDATION ADDED BY SREERAJ 3-1-25
            if (
                (values.profile_image || values.profile_image !== '') &&
                (values.profile_image.type !== "image/png" && values.profile_image.type !== "image/jpeg")
            ) {
                ErrorToast("Only PNG or JPEG image formats are supported.");
                return;
            }

            const maxFileSize = 2 * 1024 * 1024; // 2MB in bytes
            if (values.profile_image && values.profile_image.size > maxFileSize) {
                console.log("File size exceeds 2MB");
                ErrorToast("Profile image size should not exceed 2MB");
                return;
            }
            const formData = new FormData();
            formData.append("user_id", userDetails?.UD_id);
            formData.append("name", values.name);
            formData.append("mobile", values.mobile);
            formData.append("profile_image", values.profile_image ? values.profile_image : '');
            user_details_update_by_user_id(formData).then((response) => {
                if (response.status == 200) {
                    SuccessToast(response?.data.message)
                    handle_modal_change(false)
                }
            }).catch((error) => {
                console.log(error)
            })
            // } else {
            //     console.log("invalid image file type");
            //     ErrorToast("Only PNG or JPEG image formats are supported.");
            // }

        }
    })
    return (
        <div className='user_profile_super_container'>
            <div className='user_profile_main_container'>
                {/* <div className='user_profile_icon_container'>
                    <div className='user_profile_icond_flex_container'>
                        <div className='user_profile_sub_icon_container'>
                            <IconButton onClick={() => {
                                setEdit(true)
                            }} ><MdModeEditOutline className='user_profile_edit_icon' /></IconButton>
                        </div>
                    </div>

                </div> */}
                <div className='user_profile_sub_container'>
                    <div>
                        <div className='user_profile_avatar_container'>
                            <div className='user_profile_avatar' >


                                <input
                                    type="file"
                                    id="profile_pic_selector"
                                    accept="image/png, image/jpeg"
                                    style={{ display: "none" }}
                                    onChange={(e) => { handleFileUpload(e) }}
                                />
                                <Avatar src={userDetails?.profile_image_url} sx={{ width: 100, height: 100 }} />
                                {is_edit && (
                                    <div className='user_profile_camera_icon_container'>
                                        <IconButton onClick={openFileUploader} ><FaCamera className='user_profile_camera_icon' /></IconButton>
                                    </div>
                                )}
                            </div>
                        </div>
                        {/* {!validFile && <span className='invalid_file_warning' >Invalid file format</span>} */}
                    </div>
                    <form id="user_details_edit_form" onSubmit={formik.handleSubmit} >

                        <div className='user_profile_details'>
                            <div className='user_profile_grid'>
                                <p className='user_profile_label'>Name :</p>
                                {is_edit ? <CustomTextField
                                    name="name"
                                    placeholder={"Enter name"}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.name}
                                    error={formik.touched.name &&
                                        Boolean(formik.errors.name)
                                    }
                                    helperText={
                                        formik.touched.name && formik.errors.name
                                    }
                                /> : <p className='user_profile_value'>{userDetails?.UD_name}</p>}

                            </div>
                            <div className='user_profile_grid'>
                                <p className='user_profile_label'>Email :</p>
                                <p className='user_profile_value'>{userDetails?.UD_email}</p>
                            </div>
                            <div className='user_profile_grid'>
                                <p className='user_profile_label'>Mobile Number :</p>
                                {is_edit ? <CustomTextField
                                    name="mobile"
                                    placeholder={"Enter mobile"}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.mobile}
                                /> : <p className='user_profile_value'>{userDetails?.UD_mobile}</p>}

                            </div>
                            <div className='user_profile_grid'>
                                <p className='user_profile_label'>Location :</p>
                                <p className='user_profile_value'>{userDetails?.location_name}</p>
                            </div>
                            <div className='user_profile_grid'>
                                <p className='user_profile_label'>User Name :</p>
                                <p className='user_profile_value'>{userDetails?.username}</p>
                            </div>
                            <div className='user_profile_grid'>
                                <p className='user_profile_label'>Role :</p>
                                <p className='user_profile_value'>{userDetails?.role_name}</p>
                            </div>
                            <div className='user_profile_grid'>
                                <p className='user_profile_label'>Status :</p>
                                <p className='user_profile_value' style={{ color: userDetails?.status_name == "Active" ? 'Green' : 'red', fontWeight: 500 }}>{userDetails?.status_name}</p>
                            </div>
                        </div>
                    </form>
                    {is_edit && (
                        <div className='user_edit_form_action_container' >
                            <div className='user_edit_form_single_row_alignment'>
                                <button className='user_edit_form_cancel_button' onClick={() => {
                                    handle_modal_change(false)
                                }}>Cancel</button>
                                <button className='user_edit_form_save_button'
                                    type="submit"
                                    form={'user_details_edit_form'}

                                >Update</button>
                            </div>
                        </div>
                    )}


                </div>
            </div>
        </div>
    )
}

export default UserProfile