import React, { useEffect, useMemo } from 'react'
import './flightTicketStyle.css'
import { BiEditAlt } from "react-icons/bi";
import { PiEye } from "react-icons/pi";
import { IoCloseCircleOutline } from "react-icons/io5";
import { RiLoopLeftFill } from "react-icons/ri";
import CustomTable from '../../../../components/custom_table/CustomTable';
import { useNavigate } from 'react-router-dom';
import { LocalStorageServices } from '../../../../services/localStorageServices';
import { useState } from 'react';
import { delete_booked_ticket, get_flight_ticket_with_pagination } from '../../../../api/process_ecr/flightTicketApis';
import { ErrorToast, SuccessToast } from '../../../../toast/toasts';
import CustomBottomPagination from '../../../../components/custom_bottom_pagination/CustomBottomPagination';
import { MdDeleteOutline } from "react-icons/md";
import FlightTicketTableFilter from './flight_ticket_table_filter/FlightTicketTableFilter';
import CustomTableColumnSelector from '../../../../components/custom_table_column_selector/CustomTableColumnSelector';
import { useDispatch, useSelector } from 'react-redux';
import { resetSearchFilter, updateColumCheckList, updateSearchFilter } from '../../../../store/process_ecr/flightTicketStore';
import FlightTicketModals from './flight_ticket_modals/FlightTicketModals';
import { TiTickOutline } from "react-icons/ti";
import FlightTicketManagerLevelModal from './flight_ticket_manage_level_modals/FlightTicketManagerLevelModal';
import { IconButton, Tooltip } from '@mui/material';
import { FaRegCommentAlt } from "react-icons/fa";
import FlightTicketCommentModal from './flight_ticket_comment_modal/FlightTicketCommentModal';
import { FaRegCirclePause } from "react-icons/fa6";
import { SiTicktick } from "react-icons/si";
import FlightTicketFinanceLevelModals from './flight_ticket_finance_level_modals/FlightTicketFinanceLevelModals';
import FlightTicketCfoLevelModal from './flight_ticket_cfo_level_modals/FlightTicketCfoLevelModal';
import { LiaFileInvoiceSolid } from "react-icons/lia";
import FlightTicketInvoiceDetailsModal from './flight_ticket_invoice_details_modal/FlightTicketInvoiceDetailsModal';
import { BsCashCoin } from "react-icons/bs";
import FlightPaymentInformationWithTransaction from './flight_payment_information_with_transaction_modal/FlightPaymentInformationWithTransaction';
import { resetNotificationRedirect } from '../../../../store/commonStore';
import CustomActionButtonWithTooltip from '../../../../components/custom_action_button_with_tooltip/CustomActionButtonWithTooltip';


function FlightTickets() {
  const { defaultColumnChecked, ecr_filter } = useSelector((state) => state.flightTicketStore)
  const filter_data_store = useSelector((state) => state.commonStore.notification_redirect)
  //GET PRIVILEGES DETAILS FROM LOCAL STORAGE
  const getPrivilegesDetailsLocalStorageData = function () {
    const data = localStorage.getItem("privileges_details");
    return data ? JSON.parse(data) : []; // Parse the JSON string or return an empty array if null
  };


  const dispatch = useDispatch()
  const [cancelModal, setCancelModal] = useState(false);
  const [dateChangeModal, setDateChangeModal] = useState(false);
  const [flightsListing, setFlightsListing] = useState([])
  const [selectedItem, setSelectedItem] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [totalItemPerPage, setTotalItemPerPage] = useState(10)
  const baseRouteUrl = `/ecrs_portal/${LocalStorageServices.getUserRole()}/process_ecr`
  const [actionModal, setActionModal] = useState(false)
  const [commentModal, setcommentModal] = useState(false)
  const [financeLevelModal, setfinanceLevelModal] = useState(false)
  const [cfoLevelModal, setCfoLevelModal] = useState(false)
  const [invoiceDetailsModal, setInvoiceDetailsModal] = useState(false)
  const [paymentInformationModal, setPaymentInformationModal] = useState(false)
  const [isReset, setIsReset] = useState(false)
  const navigate = useNavigate()
  useEffect(() => {
    const modify_ecr_filter = { ...ecr_filter }
    if (filter_data_store?.filter_data) {
      modify_ecr_filter["FBD_PNR"] = filter_data_store?.filter_data
      dispatch(updateSearchFilter({ ...modify_ecr_filter }))
    } else {
      modify_ecr_filter["FBD_PNR"] = ""
    }
    get_booking_list(pageNumber, modify_ecr_filter.FBD_PNR ? modify_ecr_filter : ecr_filter, isReset)
  }, [pageNumber, totalItemPerPage, actionModal, financeLevelModal, cfoLevelModal, cancelModal, dateChangeModal, paymentInformationModal, isReset])

  const get_booking_list = (pageNumber, filterValue) => {
    const formData = new FormData();
    formData.append('page', pageNumber);
    formData.append('pageItem_count', totalItemPerPage);
    if (filterValue) {
      for (const key in filterValue) {
        if (filterValue[key]) {
          formData.append(key, filterValue[key]);
        }

      }
    }
    get_flight_ticket_with_pagination(formData).then((response) => {
      setFlightsListing(response.data.data)
      setTotalPages(response.data.last_page)
    }).catch((error) => {
      let message = "";
      for (const key in error.data.errors) {
        if (error.data.errors.hasOwnProperty(key)) {
          const keyMessages = error.data.errors[key].map(message => message);
          message = message.concat(keyMessages);
        }
      }
      ErrorToast(message)
    })
  }

  // Fetch privileges data once
  const privileges = getPrivilegesDetailsLocalStorageData();

  // Check if Flight Tickets has View permission
  const isShowProcessEcr_Flight_Tickets_View = privileges.some(item =>
    item.module_name === "Process ECR" &&
    item.submodules?.["Flight Tickets"]?.privileges?.some(priv => priv?.name === 'View')
  );

  // Check if Flight Tickets has Edit permission
  const isFlight_Tickets_Edit = privileges.some(item =>
    item.module_name === "Process ECR" &&
    item.submodules?.["Flight Tickets"]?.privileges?.some(priv => priv?.name === 'Edit')
  );

  // Check if Flight Tickets has Cancel permission
  const isFlight_Tickets_Cancel = privileges.some(item =>
    item.module_name === "Process ECR" &&
    item.submodules?.["Flight Tickets"]?.privileges?.some(priv => priv?.name === 'Cancel')
  );

  const isFlight_Tickets_Reject = privileges.some(item =>
    item.module_name === "Process ECR" &&
    item.submodules?.["Flight Tickets"]?.privileges?.some(priv => priv?.name === 'Reject')
  );

  // Check if Flight Tickets has Review permission
  const isFlight_Tickets_Review = privileges.some(item =>
    item.module_name === "Process ECR" &&
    item.submodules?.["Flight Tickets"]?.privileges?.some(priv => priv?.name === 'Review')
  );

  // Check if Flight Tickets has Approve permission
  const isFlight_Tickets_Approve = privileges.some(item =>
    item.module_name === "Process ECR" &&
    item.submodules?.["Flight Tickets"]?.privileges?.some(priv => priv?.name === 'Approve')
  );

  // Check if Flight Tickets has Hold permission
  const isFlight_Tickets_Hold = privileges.some(item =>
    item.module_name === "Process ECR" &&
    item.submodules?.["Flight Tickets"]?.privileges?.some(priv => priv?.name === 'Hold')
  );

  // Check if Flight Tickets has DateChange permission
  const isFlight_Tickets_DateChange = privileges.some(item =>
    item.module_name === "Process ECR" &&
    item.submodules?.["Flight Tickets"]?.privileges?.some(priv => priv?.name === 'Date Change')
  );

  // Check if Flight Tickets has Verify permission
  const isFlight_Tickets_Verify = privileges.some(item =>
    item.module_name === "Process ECR" &&
    item.submodules?.["Flight Tickets"]?.privileges?.some(priv => priv?.name === "Verify")
  );

  // Check if Flight Tickets has transaction view permission
  const isFlight_Tickets_Transaction_View = privileges.some(item =>
    item.module_name === "Process ECR" &&
    item.submodules?.["Flight Tickets"]?.privileges?.some(priv => priv?.name === "Transaction view")
  );

  const Columns = [
    {
      title: "#",
      dataIndex: "key",
      key: "key",

    },
    {
      title: "Pnr",
      dataIndex: "pnr",
      key: "pnr",
    },
    {
      title: "Airline Name",
      dataIndex: "airline_name",
      key: "airline_name",
    },
    {
      title: "Pax Number",
      dataIndex: "pax_number",
      key: "pax_number",
    },
    {
      title: "Sector",
      dataIndex: "sector",
      key: "sector",
    },
    {
      title: "Issue Date",
      dataIndex: "issue_date",
      key: "issue_date",
    },
    {
      title: "Travel Date",
      dataIndex: "travel_date",
      key: "travel_date",
    },
    {
      title: "Return Date",
      dataIndex: "return_date",
      key: "return_date",
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
    },
    {
      title: "Updated At",
      dataIndex: "updated_at",
      key: "updated_at",
    },
    {
      title: "Payment Status",
      dataIndex: "payment_name",
      key: "payment_name",
    },
    {
      title: "Status",
      dataIndex: "ecr_status_name",
      key: "ecr_status_name",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      action: (row) => (
        <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
          {isShowProcessEcr_Flight_Tickets_View &&

            <CustomActionButtonWithTooltip
              title="View"
              placement="top"
              onClick={() => {
                navigate(baseRouteUrl + `/${row.id}/view_flight_ticket`)
              }}
            >
              <PiEye className="flight_ticket_action_view" />
            </CustomActionButtonWithTooltip>}

          {/* <IconButton>
            <PiEye className='flight_ticket_action_view' onClick={() => {
              navigate(baseRouteUrl + `/${row.id}/view_flight_ticket`)
            }} />
          </IconButton>} */}

          {/* Finance Action handle */}
          {row.ecr_status === 3 || row.ecr_status === 11 || row.ecr_status === 15 || row.ecr_status === 6 ? (
            <>
              {isFlight_Tickets_Hold &&

                <CustomActionButtonWithTooltip
                  title="Hold"
                  placement="top"
                  onClick={() => {
                    setfinanceLevelModal(true)
                    setSelectedItem({ ...row, 'type': 'OnHold' })
                  }}
                >
                  <FaRegCirclePause className='flight_ticket_action_onhold' />
                </CustomActionButtonWithTooltip>}

              {/* <Tooltip title="Hold" placement="top">
                <IconButton onClick={() => {
                  setfinanceLevelModal(true)
                  setSelectedItem({ ...row, 'type': 'OnHold' })
                }}>
                  <FaRegCirclePause className='flight_ticket_action_onhold' />
                </IconButton>
              </Tooltip>
              } */}
            </>

          ) : <></>}
          {row.ecr_status === 3 || row.ecr_status === 7 || row.ecr_status === 11 || row.ecr_status === 15 || row.ecr_status === 6 ? (
            <>
              {isFlight_Tickets_Verify &&
                <CustomActionButtonWithTooltip
                  title="Verify"
                  placement="top"
                  onClick={() => {
                    setfinanceLevelModal(true)
                    setSelectedItem({ ...row, 'type': 'Verified' })
                  }}
                >
                  <SiTicktick className='flight_ticket_action_verified' />
                </CustomActionButtonWithTooltip>

                //  <Tooltip title="Verify" placement="top">
                //   <IconButton onClick={() => {
                //     setfinanceLevelModal(true)
                //     setSelectedItem({ ...row, 'type': 'Verified' })
                //   }}>
                //     <SiTicktick className='flight_ticket_action_verified' />
                //   </IconButton>
                // </Tooltip>
              }
            </>

          ) : <></>}

          {/* Manager Action handle  and also check submitted status  */}
          {/* {(userDetails.UD_roleID == 4) && (row.ecr_status === 2 || row.ecr_status === 14 || row.ecr_status === 10) ? <>
            {isFlight_Tickets_Review && <Tooltip title="Review" placement="top">
              <IconButton>
                <TiTickOutline className='flight_ticket_action_approve' onClick={() => {
                  setSelectedItem({ ...row, 'type': 'Approve' })
                  setActionModal(true)
                }} />
              </IconButton>
            </Tooltip>} */}

          {/*Manager Action handle  and also check submitted status ( REMOVED THE MANAGER ROLE ID)  */}
          {(row.ecr_status === 2 || row.ecr_status === 14 || row.ecr_status === 10) ? <>
            {isFlight_Tickets_Review &&
              <CustomActionButtonWithTooltip
                title="Review"
                placement="top"
                onClick={() => {
                  setSelectedItem({ ...row, 'type': 'Approve' })
                  setActionModal(true)
                }}
              >
                <TiTickOutline className='flight_ticket_action_approve' />
              </CustomActionButtonWithTooltip>
              // <Tooltip title="Review" placement="top">
              //   <IconButton>
              //     <TiTickOutline className='flight_ticket_action_approve' onClick={() => {
              //       setSelectedItem({ ...row, 'type': 'Approve' })
              //       setActionModal(true)
              //     }} />
              //   </IconButton>
              // </Tooltip>
            }
            {isFlight_Tickets_Reject &&
              <CustomActionButtonWithTooltip
                title="Reject"
                placement="top"
                onClick={() => {
                  setSelectedItem({ ...row, 'type': 'Reject' })
                  setActionModal(true)
                }}
              >
                <IoCloseCircleOutline className='flight_ticket_action_reject' />
              </CustomActionButtonWithTooltip>
              // <Tooltip title="Reject" placement="top">
              //   <IconButton>
              //     <IoCloseCircleOutline className='flight_ticket_action_reject' onClick={() => {
              //       setSelectedItem({ ...row, 'type': 'Reject' })
              //       setActionModal(true)
              //     }} />
              //   </IconButton>
              // </Tooltip>
            }

          </> : <></>}

          {/* Staff Action handle */}
          {/* {userDetails.UD_roleID === 6 ? <> */}
          {((row.ecr_status == 1 || row.ecr_status == 6) && (isFlight_Tickets_Edit)) ? (
            <CustomActionButtonWithTooltip
              title="Edit"
              placement="top"
              onClick={() => {
                navigate(baseRouteUrl + `/${row.id}/update_flight_ticket`)
              }}
            >
              <BiEditAlt className='flight_ticket_action_edit' />
            </CustomActionButtonWithTooltip>

            // <IconButton>
            //   <BiEditAlt className='flight_ticket_action_edit' onClick={() => {
            //     navigate(baseRouteUrl + `/${row.id}/update_flight_ticket`)
            //   }} />
            // </IconButton>

          ) : <></>}
          {row.ecr_status == 5 || row.ecr_status == 9 || row.ecr_status == 13 || row.ecr_status == 17 ? (
            <>
              {isFlight_Tickets_Cancel &&
                <CustomActionButtonWithTooltip
                  title="Cancel"
                  placement="top"
                  onClick={() => {
                    setSelectedItem(row)
                    setCancelModal(true)
                  }}
                >
                  <IoCloseCircleOutline className='flight_ticket_action_cancel_ticket' />
                </CustomActionButtonWithTooltip>
                // <IconButton>
                //   <IoCloseCircleOutline className='flight_ticket_action_cancel_ticket' onClick={() => {
                //     setSelectedItem(row)
                //     setCancelModal(true)
                //   }} />
                // </IconButton>
              }
              {isFlight_Tickets_DateChange &&
                <CustomActionButtonWithTooltip
                  title="Date Reshedule"
                  placement="top"
                  onClick={() => {
                    setSelectedItem(row)
                    setDateChangeModal(true)
                  }}
                >
                  <RiLoopLeftFill className='flight_ticket_action_re_shedule' />
                </CustomActionButtonWithTooltip>
                //  <IconButton>
                //   <RiLoopLeftFill className='flight_ticket_action_re_shedule' onClick={() => {
                //     setSelectedItem(row)
                //     setDateChangeModal(true)
                //   }} />
                // </IconButton>
              }

            </>
          ) : <></>}
          {/* </> : <></>} */}
          {/* //CFO */}
          {(row.ecr_status === 4 || row.ecr_status === 12 || row.ecr_status === 16) ? <>
            {isFlight_Tickets_Approve &&
              <CustomActionButtonWithTooltip
                title="Approve"
                placement="top"
                onClick={() => {
                  setSelectedItem({ ...row, 'type': 'Approve' })
                  setCfoLevelModal(true)
                }}
              >
                <TiTickOutline className='flight_ticket_action_approve' />
              </CustomActionButtonWithTooltip>
              // <Tooltip title="Approve" placement="top">
              //   <IconButton>
              //     <TiTickOutline className='flight_ticket_action_approve' onClick={() => {
              //       setSelectedItem({ ...row, 'type': 'Approve' })
              //       setCfoLevelModal(true)
              //     }} />
              //   </IconButton>
              // </Tooltip>
            }

            {isFlight_Tickets_Reject &&

              <CustomActionButtonWithTooltip
                title="Reject"
                placement="top"
                onClick={() => {
                  setSelectedItem({ ...row, 'type': 'Reject' })
                  setCfoLevelModal(true);
                }}
              >
                <IoCloseCircleOutline className="flight_ticket_action_reject" />
              </CustomActionButtonWithTooltip>}
            {/* CREATED BY SREERAJ 18-12-24 */}
          </> : <></>}
          {
            row.ecr_status != 1 && (
              <CustomActionButtonWithTooltip
                title="comment"
                placement="top"
                onClick={() => {
                  setSelectedItem({ ...row })
                  setcommentModal(true)
                }}
              >
                <FaRegCommentAlt className='flight_ticket_action_comment' />
              </CustomActionButtonWithTooltip>
              // <IconButton>
              //   <FaRegCommentAlt className='flight_ticket_action_comment' onClick={() => {
              //     setSelectedItem({ ...row })
              //     setcommentModal(true)
              //   }} />
              // </IconButton>
            )
          }
          {
            (row.ecr_status === 4 || row.ecr_status === 5 || row.ecr_status === 7 || row.ecr_status === 8 || row.ecr_status === 9 || row.ecr_status === 12 || row.ecr_status === 13 || row.ecr_status === 14 || row.ecr_status === 16 || row.ecr_status === 17) || (row.ecr_status === 6) ? <>
              <CustomActionButtonWithTooltip
                title="Invoice"
                placement="top"
                onClick={() => {
                  setInvoiceDetailsModal(true)
                  setSelectedItem(row?.all_data)
                }}
              >
                <LiaFileInvoiceSolid className='flight_ticket_action_invoice' />
              </CustomActionButtonWithTooltip>
              {/* <Tooltip title="Invoice" placement="top">
                <IconButton>
                  <LiaFileInvoiceSolid className='flight_ticket_action_invoice' onClick={() => {
                    setInvoiceDetailsModal(true)
                    setSelectedItem(row?.all_data)
                  }} />
                </IconButton>
              </Tooltip> */}
            </>
              : <></>
          }
          {/* <RiFileList3Line className='flight_ticket_action_amendment' onClick={() => {
                  navigate(baseRouteUrl + `/${row.id}/amendment_flight_ticket`)
            }} /> */}
          {
            ((row.ecr_status != 1 && row.ecr_status != 2) && (isFlight_Tickets_Transaction_View)) ? <>

              <CustomActionButtonWithTooltip
                title="Transaction"
                placement="top"
                onClick={() => {
                  setSelectedItem(row?.all_data)
                  setPaymentInformationModal(true)
                }}
              >
                <BsCashCoin className='flight_ticket_action_cash' />
              </CustomActionButtonWithTooltip>

              {/* <Tooltip title="Transaction" placement="top">
                <IconButton>
                  <BsCashCoin className='flight_ticket_action_cash' onClick={() => {
                    setSelectedItem(row?.all_data)
                    setPaymentInformationModal(true)
                  }} />
                </IconButton>
              </Tooltip> */}


            </> : <></>
          }

        </div >
      ),
    },
  ];

  const datas = () => {
    const result =
      flightsListing && flightsListing.map((item, index) => {
        return {
          key: pageNumber * totalItemPerPage - (totalItemPerPage - (index + 1)),
          id: item.FBD_Id,
          ecr_status: item.FBD_ECRstatusID,
          ecr_status_name: item?.ecr_status?.ESL_name,
          ecr_cancelled_status: item?.FBD_cancel_status,
          ecr_date_change_status: item?.FBD_dateChange_status,
          pnr: item.FBD_PNR,
          pax_number: item.FBD_pax_count,
          sector: item.FBD_sector,
          issue_date: item.FBD_issue_date ? item.FBD_issue_date.split('T')[0] : item.FBD_issue_date,
          travel_date: item.FBD_travel_date ? item.FBD_travel_date.split('T')[0] : item.FBD_travel_date,
          return_date: item.FBD_return_date ? item.FBD_return_date.split('T')[0] : item.FBD_return_date,
          airline_name: item?.get_flight_name?.AirlineName,
          payment_name: item?.get_payment_status?.EPS_name,
          passenger_list: item?.passanges,
          all_data: item,
          created_at: item?.FBD_created_at,
          updated_at: item?.FBD_updated_at,
          trip_type: item?.FBD_trip_type,
          is_highlight_row: (item?.FBD_dateChange_status !== 0 || item?.FBD_cancel_status !== 0) ? true : false,
          highlight_color: item?.FBD_cancel_status !== 0 ? 'red' : item?.FBD_dateChange_status !== 0 ? 'orange' : ''
        };
      });
    return result;
  };

  const handleModalClose = (type, is_close) => {
    if (type == "Cancel") {
      setCancelModal(is_close)
      setSelectedItem(null)
    } else if (type == "DateChange") {
      setDateChangeModal(is_close)
      setSelectedItem(null)
    }
  };
  const updatePagination = (pageNumber) => {
    setPageNumber(pageNumber)
  }
  const updateItemPageCount = (pageItemCount) => {
    setTotalItemPerPage(pageItemCount)
  }
  const searchfilter = (value) => {
    if (value) {
      setPageNumber(1)
    } else {
      dispatch(resetNotificationRedirect())
      dispatch(resetSearchFilter())
      setPageNumber(1)
    }
    setIsReset(!isReset)
  }

  const updateCheckedColumn = (index) => {
    dispatch(updateColumCheckList(index))
  }
  const handleActionModalClose = (is_close) => {
    setActionModal(is_close)
    setSelectedItem(null)
  };
  const handleCommentModalClose = (is_close) => {
    setcommentModal(is_close)
    setSelectedItem(null)
  };
  const handleFinanceLevelModalClose = (is_close) => {
    setfinanceLevelModal(is_close)
    setSelectedItem(null)
  };
  const handleCfoLevelModalClose = (is_close) => {
    setCfoLevelModal(is_close)
    setSelectedItem(null)
  };
  const handleInvoiceDetailsModalClose = (is_close) => {
    setInvoiceDetailsModal(is_close)
    setSelectedItem(null)
  };

  const handlePaymentInformationTransactionModalClose = (is_close) => {
    setPaymentInformationModal(is_close)
    setSelectedItem(null)
  };

  const isShowProcessEcr_Flight_Tickets_Add = privileges.some(item =>
    item.module_name === "Process ECR" &&
    item.submodules?.["Flight Tickets"]?.privileges?.some(priv => priv?.name === 'Add')
  );

  return (
    <div className='flight_ticket_module_main_container'>
      <CustomTableColumnSelector columns={Columns} checkedColumns={defaultColumnChecked} onChange={updateCheckedColumn} />
      <div>
        <div className='flight_ticket_module_header'>
          <p className='flight_ticket_title'>Flight Tickets</p>
          {/* userDetails?.UD_roleID === 6 && commented by sreeraj for privileges implementation */}
          {isShowProcessEcr_Flight_Tickets_Add && (
            <button className='flight_ticket_add_button' onClick={() => {
              navigate(baseRouteUrl + `/create_new_flight_ticket`)
            }}>Add New</button>
          )}

        </div>
        <div>
          <FlightTicketTableFilter searchFilter={searchfilter} />
        </div>
      </div>
      <CustomTable columns={Columns.filter((_, index) => defaultColumnChecked.includes(index))} rows={datas()} />
      <div>
        <div>
          <CustomBottomPagination total_items_per_page={totalItemPerPage} total_page_count={totalPages} current_page={pageNumber} on_change_value={updatePagination} on_page_count_update={updateItemPageCount} />
        </div>
        <FlightTicketCommentModal open={commentModal} handleClose={handleCommentModalClose} booking_id={selectedItem?.id} />
        <FlightTicketModals selected_item={selectedItem} open_cancel_modal={cancelModal} open_date_change_modal={dateChangeModal} handle_modal_close={handleModalClose} />
        <FlightTicketManagerLevelModal selected_item={selectedItem} open_modal={actionModal} modal_close={handleActionModalClose} />
        <FlightTicketFinanceLevelModals selected_item={selectedItem} open_modal={financeLevelModal} modal_close={handleFinanceLevelModalClose} />
        <FlightTicketCfoLevelModal selected_item={selectedItem} open_modal={cfoLevelModal} modal_close={handleCfoLevelModalClose} />
        {invoiceDetailsModal && <FlightTicketInvoiceDetailsModal selected_item={selectedItem} open_modal={invoiceDetailsModal} modal_close={handleInvoiceDetailsModalClose} />}
        {paymentInformationModal && <FlightPaymentInformationWithTransaction selected_item={selectedItem} open_modal={paymentInformationModal} modal_close={handlePaymentInformationTransactionModalClose} />}
      </div>
    </div>
  )
}

export default FlightTickets