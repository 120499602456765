
import React, { useEffect, useState } from 'react'
// import './PackagesStyles.css'
import { LocalStorageServices } from '../../../../services/localStorageServices';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import CustomTable from '../../../../components/custom_table/CustomTable';
import { PiEye } from 'react-icons/pi';
import { BiEditAlt } from 'react-icons/bi';
import { MdOutlineDeleteOutline } from 'react-icons/md';
import { ErrorToast, SuccessToast } from '../../../../toast/toasts';
import CustomBottomPagination from '../../../../components/custom_bottom_pagination/CustomBottomPagination';
import { IconButton, Tooltip } from '@mui/material';
import { IoCloseCircleOutline } from 'react-icons/io5';
import { TiTickOutline } from 'react-icons/ti';
import { FaRegCirclePause } from "react-icons/fa6";
import { SiTicktick } from 'react-icons/si';
import { FaRegCommentAlt } from 'react-icons/fa';
import { LiaFileInvoiceSolid } from 'react-icons/lia';
import { useSelector } from 'react-redux';
import { delete_Passport_Booking_by_id, getPassport_Booking_lists_with_pagination } from '../../../../api/PassportApi';
import CustomDeleteConfirmModal from '../../../../components/custom_delete_confirm_modal/CustomDeleteConfirmModal';
import CancelPassport from './passport_modals/CancelPassport';
import PassportReusableModal from './passport_reusable_modals/PassportReusableModal';
import PassportCommentModal from './passport_comment_modal/PassportCommentModal';
import PassportInvoiceDetailsModal from './passport_invoice_details_modal/PassportInvoiceDetailsModal';
import PassportFinanceLevelModals from './passport_finance_level_modals/PassportFinanceLevelModals';
import PassportFilter from './passport_filter/PassportFilter';
import { hasPrivilege } from '../../../../common_privilige_methods/Privilege_Methods';

function Passport() {
  const navigate = useNavigate()
  const filter_data_store = useSelector((state) => state.commonStore.notification_redirect)

  const baseRouteUrl = `/ecrs_portal/${LocalStorageServices.getUserRole()}/process_ecr`


  const [cabListings, setCabListings] = useState([])
  const [pageNumber, setPageNumber] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [totalItemPerPage, setTotalItemPerPage] = useState(10)

  const [selectedId, setSelectedId] = useState("");
  const [searchFilter, setSearchFilter] = useState(null)
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);


  const [selectedItem, setSelectedItem] = useState(null)
  const [cancelModal, setCancelModal] = useState(false);

  const [financeLevelModal, setfinanceLevelModal] = useState(false)
  const [actionModal, setActionModal] = useState(false)
  const [cfoLevelModal, setCfoLevelModal] = useState(false)

  const [commentModal, setcommentModal] = useState(false)
  const [invoiceDetailsModal, setInvoiceDetailsModal] = useState(false)


  // // Get privileges details from local storage
  // const getPrivilegesDetails = () => JSON.parse(localStorage.getItem("privileges_details") || "[]");

  // // Check if a specific privilege exists in User Management -> User
  // const hasPrivilege = (privilegeName) =>
  //   getPrivilegesDetails().some(
  //     (item) =>
  //       item.module_name === "Process ECR" &&
  //       item.submodules?.["Passport"]?.privileges?.some((priv) => priv.name === privilegeName)
  //   );

  const isPrivileges_Add = hasPrivilege("Process ECR", "Passport", "Add");
  const isPrivileges_View = hasPrivilege("Process ECR", "Passport", "View");
  const isPrivileges_Edit = hasPrivilege("Process ECR", "Passport", "Edit");
  const isPrivileges_Delete = hasPrivilege("Process ECR", "Passport", "Delete");
  const isPrivileges_Hold = hasPrivilege("Process ECR", "Passport", "Hold");
  const isPrivileges_Reject = hasPrivilege("Process ECR", "Passport", "Reject");
  const isPrivileges_Review = hasPrivilege("Process ECR", "Passport", "Review");
  const isPrivileges_Verify = hasPrivilege("Process ECR", "Passport", "Verify");
  const isPrivileges_Approve = hasPrivilege("Process ECR", "Passport", "Approve");

  useEffect(() => {
    if (!actionModal && !cancelModal && !deleteConfirmation && !financeLevelModal) {
      if (!filter_data_store?.filter_data) {
        getList(pageNumber, searchFilter)
      }
    }
  }, [pageNumber, totalItemPerPage, deleteConfirmation, cancelModal, actionModal, financeLevelModal,filter_data_store]) //deleteConfirmation for temprory page refresh handling 

  const getList = (pageNumber, filterValue) => {
    const formData = new FormData();
    formData.append('page', pageNumber);
    formData.append('pageItem_count', totalItemPerPage);
    if (filterValue) {
      for (const key in filterValue) {
        formData.append(key, filterValue[key] ?? '');
      }
    }
    getPassport_Booking_lists_with_pagination(formData).then((response) => {
      if (response.data.code === 200) {
        setCabListings(response?.data.data)
        setTotalPages(response.data.pagination?.last_page)
      } else {
        setCabListings([])
        if (pageNumber > 1) {
          setPageNumber(pageNumber - 1)
        }
      }
    }).catch((error) => {
      console.log(error)
    })
  }

  const Columns = [
    {
      title: "#",
      dataIndex: "key",
      key: "key",
    },
    {
      title: "File Name",
      dataIndex: "enquiry_number",
      key: "enquiry_number",
    },
    {
      title: "Application Date",
      dataIndex: "issue_date",
      key: "issue_date",
    },
    {
      title: "Client",
      dataIndex: "client",
      key: "client",
    },
    {
      title: "Client Amount",
      dataIndex: "client_amount",
      key: "client_amount",
    },
    {
      title: "Supplier ",
      dataIndex: "supplier",
      key: "supplier",
    },
    {
      title: "Supplier Amount",
      dataIndex: "supplier_amount",
      key: "supplier_amount",
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },

    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      action: (row) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ display: 'flex', alignItems: 'center', 'gap': 5 }} >
            {isPrivileges_View &&
              <IconButton>
                <PiEye className='packages_action_view' onClick={() => {
                  navigate(baseRouteUrl + `/${row.id}/view_passport`)
                }} />
              </IconButton>}
            {/* Staff Action handle */}
            {((row.ecr_status == 1 || row.ecr_status == 6) && (isPrivileges_Edit)) ? (
              <>
                <IconButton>
                  <BiEditAlt className='packages_action_edit' onClick={() => {
                    navigate(baseRouteUrl + `/${row.id}/update_passport`)
                  }} />
                </IconButton>

                {isPrivileges_Delete && <Tooltip title="Delete" placement="top">
                  <IconButton>
                    <MdOutlineDeleteOutline className='packages_action_delete' onClick={() => {
                      setSelectedId(row.id)
                      setDeleteConfirmation(true)
                    }} />
                  </IconButton>
                </Tooltip>
                }
              </>

            ) : <></>}
            {/* {row.ecr_status == 5 ? (
              <>
                {isPrivileges_Cancel && <Tooltip title="Cancel" placement="top">
                  <IconButton>
                    <IoCloseCircleOutline className='flight_ticket_action_cancel_ticket' onClick={() => {
                      setSelectedItem(row)
                      setCancelModal(true)
                    }} />
                  </IconButton>
                </Tooltip>

                }
              </>
            ) : <></>} */}
          </div>
          {/* Finance Action handle */}
          {row.ecr_status === 3 || row.ecr_status === 6 ? (
            <>
              {isPrivileges_Hold && <Tooltip title="Hold" placement="top">
                <IconButton onClick={() => {
                  //setfinanceLevelModal old code
                  setActionModal(true)
                  setSelectedItem({ ...row, 'type': 'Hold' })
                }}>
                  <FaRegCirclePause className='flight_ticket_action_onhold' />
                </IconButton>
              </Tooltip>}
            </>
          ) : <></>}

          {row.ecr_status === 3 || row.ecr_status === 7 || row.ecr_status === 6 ? (
            <>
              {isPrivileges_Verify && <Tooltip title="Verify" placement="top">
                <IconButton onClick={() => {
                  setfinanceLevelModal(true)
                  setSelectedItem({ ...row, 'type': 'Verify' })
                }}>
                  <SiTicktick className='flight_ticket_action_verified' />
                </IconButton>
              </Tooltip>}
            </>
          ) : <>
            {/* FOR HANDLE PACKAGE CANCELATION VERIFY BY FINANCE */}
            {row.ecr_status === 11 && isPrivileges_Verify && <Tooltip title="Verify" placement="top">
              <IconButton onClick={() => {
                setfinanceLevelModal(true)
                setSelectedItem({ ...row, 'type': 'CancelVerified' })
              }}>
                <SiTicktick className='flight_ticket_action_verified' />
              </IconButton>
            </Tooltip>}</>}
          {/*Manager Action handle  and also check submitted status  */}
          {(row.ecr_status === 2) ? <>
            {isPrivileges_Review && <Tooltip title="Review" placement="top">
              <IconButton>
                <TiTickOutline className='flight_ticket_action_approve' onClick={() => {
                  setSelectedItem({ ...row, 'type': 'Review' })
                  setActionModal(true)
                }} />
              </IconButton>
            </Tooltip>}
            {isPrivileges_Reject && <Tooltip title="Reject" placement="top">
              <IconButton>
                <IoCloseCircleOutline className='flight_ticket_action_reject' onClick={() => {
                  setSelectedItem({ ...row, 'type': 'Reject' })
                  setActionModal(true)
                }} />
              </IconButton>
            </Tooltip>}

          </> : (row.ecr_status === 10) ? <>
            {/* FOR HANDLE PACKAGE CANCELATION REVIEW BY MANAGER */}
            {isPrivileges_Review && <Tooltip title="Review" placement="top">
              <IconButton>
                <TiTickOutline className='flight_ticket_action_approve' onClick={() => {
                  setSelectedItem({ ...row, 'type': 'CancelReviewed' })
                  setActionModal(true)
                }} />
              </IconButton>
            </Tooltip>}

          </> : <></>}

          {/* //CFO */}
          {(row.ecr_status === 4) ? <>
            {isPrivileges_Approve && <Tooltip title="Approve" placement="top">
              <IconButton>
                <TiTickOutline className='flight_ticket_action_approve' onClick={() => {
                  setSelectedItem({ ...row, 'type': 'Approve' })
                  setActionModal(true)
                  // setCfoLevelModal(true)
                }} />
              </IconButton>
            </Tooltip>}

            {isPrivileges_Reject && <Tooltip title="Reject" placement="top">
              <IconButton>
                <IoCloseCircleOutline className='flight_ticket_action_reject' onClick={() => {
                  setSelectedItem({ ...row, 'type': 'CFOReject' })
                  setActionModal(true)
                  // setCfoLevelModal(true)
                }} />
              </IconButton>
            </Tooltip>}
            {/* FOR HANDLE PACKAGE CANCELATION Approve BY CFO */}
          </> : <>{row.ecr_status === 12 && <>
            {isPrivileges_Approve && <Tooltip title="Approve" placement="top">
              <IconButton>
                <TiTickOutline className='flight_ticket_action_approve' onClick={() => {
                  setSelectedItem({ ...row, 'type': 'CancelApproved' })
                  setActionModal(true)
                }} />
              </IconButton>
            </Tooltip>}</>}</>}
          {true && (
            <IconButton>
              <FaRegCommentAlt className='flight_ticket_action_comment' onClick={() => {
                setSelectedItem({ ...row })
                setcommentModal(true)
              }} />
            </IconButton>
          )}
          {(row.ecr_status === 4 || row.ecr_status === 5 || row.ecr_status === 6 || row.ecr_status === 7 || row.ecr_status === 8) && (row?.invoice?.length !== 0) &&
            <Tooltip title="Invoice" placement="top">
              <IconButton>
                <LiaFileInvoiceSolid className='flight_ticket_action_invoice' onClick={() => {
                  setInvoiceDetailsModal(true)
                  setSelectedItem({ ...row })
                }} />
              </IconButton>
            </Tooltip>
          }

        </div>
      ),
    },
  ];

  const handleDeleteModalClose = (is_close) => {
    setDeleteConfirmation(is_close);
    setSelectedId(null)
  };
  const datas = () => {
    const result =
      cabListings &&
      cabListings?.map((item, index) => {
        return {
          key: pageNumber * totalItemPerPage - (totalItemPerPage - (index + 1)),
          id: item.booking_id,
          enquiry_number: item.enquiry_number,
          issue_date: item.issue_date,
          supplier: item.supplier,
          supplier_amount: item.supplier_amount,
          service_fee: item.service_fee,
          client: item.client,
          client_amount: item.client_amount,
          status: item.status,
          invoice: item.invoice,
          ecr_status: item.status_id,
        };
      });
    return result;
  };


  const updatePagination = (pageNumber) => {
    setPageNumber(pageNumber)
  }
  const updateItemPageCount = (pageItemCount) => {
    setTotalItemPerPage(pageItemCount)
  }

  const searchfilter = (value) => {
    setSearchFilter(value)
    if (value) {
      getList(pageNumber, value)
    } else {
      getList(1, value)
      setPageNumber(1)
    }
  }

  const handleModalClose = (is_close) => {
    setCancelModal(is_close)
    setSelectedItem(null)
  };


  //MANAGER LELE MODAL CLOSE
  const handleActionModalClose = (is_close) => {
    setActionModal(is_close)
    setSelectedItem(null)
  };

  const handleFinanceLevelModalClose = (is_close) => {
    setfinanceLevelModal(is_close)
    setSelectedItem(null)
  };
  const handleCfoLevelModalClose = (is_close) => {
    setCfoLevelModal(is_close)
    setSelectedItem(null)
  };
  const handleCommentModalClose = (is_close) => {
    setcommentModal(is_close)
    setSelectedItem(null)
  };

  const handleInvoiceDetailsModalClose = (is_close) => {
    setInvoiceDetailsModal(is_close)
    setSelectedItem(null)
  };

  const deleteCab = () => {
    if (selectedId) {
      const payload = {
        booking_id: selectedId
      }
      delete_Passport_Booking_by_id(payload).then((response) => {
        if (response.code === 200) {
          handleDeleteModalClose(false)
          SuccessToast(response?.message)
        }
      }).catch((error) => {
        handleDeleteModalClose(false)
        ErrorToast(error?.data?.message)
      })
    }
  }

  return (

    <div className='packages_module_main_container'>
      <p className='packages_title'>Passport</p>
      <div>

        <div className='user_module_filter'>
          <PassportFilter searchFilter={searchfilter} />
          {isPrivileges_Add && <button className='packages_add_button' onClick={() => {
            navigate(baseRouteUrl + `/create_new_passport`)
          }}>Add New</button>}
        </div>
        <div className='packages_table_main_container'>
          <CustomTable columns={Columns} rows={datas()} />
        </div>
        <div>
          <CustomBottomPagination total_items_per_page={totalItemPerPage} total_page_count={totalPages} current_page={pageNumber} on_change_value={updatePagination} on_page_count_update={updateItemPageCount} />
        </div>
      </div>
      <CustomDeleteConfirmModal module_name={'Passport'} open_modal={deleteConfirmation} close_modal={handleDeleteModalClose} confirm_function={deleteCab} />

      {/* <CancelPassport selected_item={selectedItem} open_modal={cancelModal} modal_close={handleModalClose} /> */}
      <PassportReusableModal selected_item={selectedItem} open_modal={actionModal} modal_close={handleActionModalClose} />
      <PassportCommentModal open={commentModal} handleClose={handleCommentModalClose} booking_id={selectedItem?.id} />

      <PassportFinanceLevelModals selected_item={selectedItem} open_modal={financeLevelModal} modal_close={handleFinanceLevelModalClose} />
      {invoiceDetailsModal && <PassportInvoiceDetailsModal selected_item={selectedItem} open_modal={invoiceDetailsModal} modal_close={handleInvoiceDetailsModalClose} />}
    </div>
  )
}

export default Passport