import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';

const CustomIconButton = styled(IconButton)(({ theme }) => ({
    backgroundColor: '#F8FAFC', // Light  background
    padding: '5px', 
    borderRadius: '50%',
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)', // Light grey shadow
    border: '1px solid #e0e0e0', // Light grey border
    transition: 'all 0.3s ease', 
    '&:hover': {
        backgroundColor: '#F8FAFC',
        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.15)', 
    },
}));

const CustomActionButtonWithTooltip = ({ title, placement = 'top', onClick, children }) => (
    <Tooltip title={title} placement={placement} arrow>
        <CustomIconButton onClick={onClick}>
            {children}
        </CustomIconButton>
    </Tooltip>
);

export default CustomActionButtonWithTooltip;