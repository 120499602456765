import React from 'react';
import CustomAutoCompleteDropdown from '../../../../components/custom_auto_complete_dropdown/CustomAutoCompleteDropdown';
import './DashboardFilterStyle.css'
import dayjs from 'dayjs';
import CustomDatePicker from '../../../../components/custom_date_picker/CustomDatePicker';
import { IconButton, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { IoCloseCircleOutline } from 'react-icons/io5';
import resetIcon from '../../../../assets/resetIcon.png'

// Styled IconButton
const CustomIconButton = styled(IconButton)(({ theme }) => ({
    backgroundColor: '#f5f5f5', // Light grey background
    padding: '5px', // Adjust padding
    borderRadius: '50%',
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)', // Light grey shadow
    border: '1px solid #e0e0e0', // Light grey border
    transition: 'all 0.3s ease', // Smooth hover effect
    '&:hover': {
        backgroundColor: '#e0e0e0', // Slightly darker on hover
        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.15)', // Stronger shadow on hover
    },
}));

export const CustomActionButtonWithTooltip = ({ title, placement = 'top', onClick, children }) => (
    <Tooltip title={title} placement={placement} arrow>
        <CustomIconButton onClick={onClick}>
            {children}
        </CustomIconButton>
    </Tooltip>
);

const DashboardFilter = ({ period, setPeriod, year, setYear, yearOptions, formik, resetFilter }) => {
    // Period options
    const periodOptions = [
        { label: "Today", value: "today" },
        { label: "Last Day", value: "yesterday" },
        { label: "Week", value: "week" },
        { label: "Month", value: "month" },
        { label: "Year", value: "year" },
        { label: "Custom Date", value: "range" },
    ];


    return (
        <form id="dashBoardFilter" onSubmit={formik.handleSubmit}>
            <div className='dashboard_main_container'>
                <div className='' style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', gap: 5 }}>
                    <div style={{ minWidth: 200 }}>
                        <CustomAutoCompleteDropdown
                            name="period"
                            placeholder="Select a period"
                            options={periodOptions}
                            onChange={(event, newValue) => setPeriod(newValue?.value || "today")}
                            value={periodOptions.find((option) => option.value === period) || null}
                        />
                    </div>

                    {period === "year" && (
                        <div style={{ width: 200 }}>
                            <CustomAutoCompleteDropdown
                                name="year"
                                placeholder="Select a year"
                                options={yearOptions}
                                onChange={(event, newValue) => setYear(newValue ? newValue.value : null)}
                                value={yearOptions.find((option) => option.value === year) || null}
                            />
                        </div>
                    )}

                    {period === "range" && (
                        <>
                            <div className="date_selection_container">
                                {/* Start Date Picker */}
                                <div className="date_selection_filter_style_1">
                                    <p className="date_selection_filter_legent_style_1">Start to End Date</p>
                                    <CustomDatePicker
                                        no_border
                                        name="start_date"
                                        maxDate={dayjs()}
                                        onChange={(date) => {
                                            if (!date) {
                                                // Clear both start_date and end_date when start_date is cleared
                                                formik.setFieldValue("start_date", null);
                                                formik.setFieldValue("end_date", null);
                                            } else {
                                                const today = dayjs().format("DD-MM-YYYY");
                                                // Prevent setting future dates
                                                const selectedDate = dayjs(date, "DD-MM-YYYY").isAfter(dayjs()) ? today : date;
                                                formik.setFieldValue("start_date", selectedDate);

                                                // Automatically set end_date to the current date if not already set
                                                if (!formik.values.end_date) {
                                                    formik.setFieldValue("end_date", today);
                                                }
                                            }
                                        }}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.start_date ? dayjs(formik.values.start_date, "DD-MM-YYYY") : null}
                                        error={formik.touched.start_date && Boolean(formik.errors.start_date)}
                                        helperText={formik.touched.start_date && formik.errors.start_date}
                                    />
                                </div>

                                {/* End Date Picker */}
                                <div className="date_selection_filter_style_1">
                                    <CustomDatePicker
                                        no_border
                                        disabled={!formik.values.start_date} // Disable until start_date is set
                                        minDate={formik.values.start_date ? dayjs(formik.values.start_date, "DD-MM-YYYY") : null}
                                        maxDate={dayjs()}
                                        name="end_date"
                                        onChange={(date) => {
                                            formik.setFieldValue("end_date", date);
                                        }}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.end_date ? dayjs(formik.values.end_date, "DD-MM-YYYY") : null}
                                        error={formik.touched.end_date && Boolean(formik.errors.end_date)}
                                        helperText={formik.touched.end_date && formik.errors.end_date}
                                    />
                                </div>

                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <CustomActionButtonWithTooltip
                                    title="Reset"
                                    placement="top"
                                    onClick={resetFilter}
                                >
                                    <img src={resetIcon} className='icon_style' />
                                </CustomActionButtonWithTooltip>
                            </div>
                        </>
                    )}

                </div>
            </div>
        </form>
    );
};

export default DashboardFilter;
