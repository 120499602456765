import React from 'react'
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
function CustomDatePicker({
    name,
    onChange,
    onBlur,
    value,
    error,
    helperText,
    FormHelperTextProps,
    disabled,
    minDate,
    no_border,
    maxDate
}) {
    const ModifiedDateOtherStyles = {
        '& .MuiInputBase-input.MuiOutlinedInput-input': {
            padding: '8px 14px',
            fontSize: '15px',
            height: '25px',
            borderColor:'red'
        },
        "& .MuiInputBase-input.Mui-disabled": {
            WebkitTextFillColor: "#272727",
        },
        "& .MuiOutlinedInput-notchedOutline": {
            border:no_border? 'none':''
          }
    };

    const ModifiedDatePickerOutline = {
        '& .MuiOutlinedInput-root': {
            fontFamily: 'Poppins',
            fontSize: '15px',
            '& fieldset': {
                borderColor:no_border?'white':'#C0C0C0',
            },
            '&:hover fieldset': {
                borderColor:no_border?'white':'#C0C0C0',
            },
            '&.Mui-focused fieldset': {
                borderColor:no_border?'white':'#C0C0C0',
            },
        },
    };
    return (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                    minDate={minDate}
                    disabled={disabled}
                    maxDate={maxDate}
                    onChange={(date) => {
                        onChange(date?.format("DD-MM-YYYY"))
                        
                    }}
                    fullWidth
                    format="DD-MM-YYYY"
                    className='custom-datepicker-input-class'
                    name={name}
                    autoComplete="off"
                    size="small"
                    onBlur={onBlur}
                    value={value}
                    slotProps={{
                        actionBar: {
                            actions: ['clear'],
                          },
                        textField: {
                            helperText: helperText ? helperText : '',
                            error: error ? error : false,
                            fullWidth: true,
                            placeholder: "DD-MM-YYYY"
                        },
                    }}

                    sx={{ ...ModifiedDatePickerOutline, ...ModifiedDateOtherStyles }}
                />
            </LocalizationProvider>
    )
}

export default CustomDatePicker