
import React, { useEffect, useState } from 'react'
import { IoAddOutline } from "react-icons/io5";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { BiEditAlt } from "react-icons/bi";
import CustomTable from '../../../../../components/custom_table/CustomTable';
import './PackagesSupplierTable.css'
import CustomModal from '../../../../../components/custom_modal/CustomModal';
import { IconButton } from '@mui/material';
import { IoIosCloseCircleOutline } from "react-icons/io";
import { useLocation, useParams } from 'react-router-dom';
import PackagesSupplierForm from '../packages_supplier_form/PackagesSupplierForm';
import { get_supplier_without_pagination } from '../../../../../api/supplierApis';
import { get_gst_List } from '../../../../../api/hotelApis';
import { get_services } from '../../../../../api/servicesApis';
function PackagesSupplierTable({ supplierId, supplier_creation_modal, handleSupplierCreationModal, isDisable, passengerCount, passengerOtherCount, passengerList, setPassengerList, updatePassengerDetails, passengerDelete, onAmountChange }) {
  let { booking_id } = useParams();
  const location = useLocation()

  const [isView, setIsView] = useState(null)
  const [open, setOpen] = useState(false);
  const [passengerDetails, setPassengerDetails] = useState(null)
  const [gst, setGst] = useState([])
  const [selectedRowDetails, setSelectedRowDetails] = useState(null)
  const [supplier, setSupplier] = useState([])
  const [servicesList, setServicesList] = useState([])
  const [amountValue, setAmountValue] = useState(null);

//  function to set the supplier amount From child Component added by Evangelin
  const handleAmountUpdate = (value) => {
    setAmountValue(value);
    onAmountChange(value); // Send the updated value to the parent
  };

  const getSupplierList = () => {
    get_supplier_without_pagination().then((response) => {
      const data = response?.data?.data.map((item) => {
        return {
          label: item.SD_name,
          value: item.SD_id,
        };
      });
      setSupplier(data)
    }).catch((error) => {
      console.log(error)
    })
  }
  const getServices = () => {
    get_services().then((response) => {
      const data = response.map((item) => {
        return {
          label: item.ES_services_name,
          value: item.ES_id,
        };
      }).filter(item => item?.value === 3 || item?.value === 6);
      setServicesList(data)
    }).catch((error) => {
      console.log(error)
    })
  }
  const getGstList = () => {
    get_gst_List().then((response) => {
      const data = response?.map((item) => {
        return {
          label: item.name,
          value: item.gst_id,
          percentage: item.percentage,

        };
      });
      setGst(data)
    }).catch((error) => {
      console.log(error)
    })
  }

  useEffect(() => {
    if (location.pathname.split('/').pop() === "view_package") {
      setIsView(true);
    } else if (location.pathname.split('/').pop() === "update_package") {
      setIsView(true);
    }
    getServices()
    getSupplierList()
    getGstList()
  }, [passengerList])

  const Columns = [
    {
      title: "Sl No",
      dataIndex: "key",
      key: "key",

    },
    {
      title: "Service",
      dataIndex: "services_name",
      key: "services_name",
    },
    {
      title: "Name",
      dataIndex: "supplier_name",
      key: "supplier_name",
    },
    {
      title: "amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "percentage",
      dataIndex: "percentage",
      key: "percentage",
    },

    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      // hide: !isDisable ? false : isView,
      hide: isDisable,
      action: (row) => (
        <div style={{ display: 'flex', alignItems: 'center', 'gap': 5 }}>
          <BiEditAlt className='package_supplier_table_action_edit' onClick={() => {
            setPassengerDetails(row)
            setOpen(true)
          }} />
          {/* {!isAmendment && ( */}
          <MdOutlineDeleteOutline className='package_supplier_table_action_delete' onClick={() => {
            passengerDelete(row.index)
          }} />
          {/* ) */}
          {/* } */}
        </div>
      ),
    },
  ];

  const handleClose = (is_close) => {
    if (!is_close) {
      setPassengerDetails(null)
    }
    setOpen(is_close);
  };
  const set_passenger_details = (details) => {
    setPassengerList(details)

  }
  const update_passenger_details = (details) => {
    updatePassengerDetails(details)
  }

  const datas = () => {
    const result =
      passengerList &&
      passengerList.map((item, index) => {
        const supplierName = supplier?.find(pt => pt.value == item.supplier_id)?.label;
        const gstPercentage = gst?.find(pt => pt.value == item.gst_id)?.percentage;
        const servicesName = servicesList.find(pt => pt.value == item.service_id)?.label;
        return {

          key: index + 1,
          index: index,
          amount: item.amount,
          gst_id: item.gst_id,
          file: item.file,
          supplier_id: item?.supplier_id,
          supplier_name: supplierName,
          service_id: item?.service_id,
          percentage: gstPercentage,
          services_name: servicesName

        };
      });
    return result;
  };
  const selectedRow = (values) => {
    setSelectedRowDetails(values)
  }

  return (
    <div>
      <CustomModal
        maxWidth={"1500px"}
        open={open}
        handleClose={handleClose}
        close={<IconButton
          aria-label="close"
          onClick={() => {
            handleClose(false)
          }}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        > <IoIosCloseCircleOutline />
        </IconButton>}
        body={
          <PackagesSupplierForm supplierId={supplierId} supplier_creation_modal={supplier_creation_modal} handleSupplierCreationModal={handleSupplierCreationModal} totalPassenger={passengerList} passengerOtherCounts={passengerOtherCount} existingPassengerDetails={passengerDetails} onClose={handleClose} setPassengerDetails={set_passenger_details} updatePassengerDetails={update_passenger_details} onAmountChange={handleAmountUpdate} />
        }
        title={!passengerDetails ? "Add Supplier" : "Update Supplier"}
      />


      <div className='package_supplier_add_passenger_action_container' >
        <div>
          {!isDisable && (
            <div className='package_supplier_table_header_action_container' tabindex="0" onClick={() => {
              setOpen(true)
            }}>
              <IoAddOutline className='package_supplier_table_action_add' />
              <p className='package_supplier_table_action_text'>Add More Supplier</p>
            </div>
          )}
        </div>

      </div>

      <div className='package_supplier_table_header_component'>
        <p className='package_supplier_table_header_text'>Supplier Details</p>
      </div>
      <div>
        <CustomTable columns={Columns.filter((item) => item.hide !== true)} rows={datas()} no_border={true} selectedRow={selectedRow} />
      </div>
    </div>
  )
}

export default PackagesSupplierTable