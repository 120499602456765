import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import LoginPage from '../auth_pages/login_page/LoginPage'
import HomePage from '../home_pages/HomePage'
import Dashboard from '../home_pages/sub_pages/dashboard/Dashboard'
import FlightTickets from '../home_pages/sub_pages/process_ecr/flight_tickets/FlightTickets'
import Packages from '../home_pages/sub_pages/process_ecr/packages/Packages'
import Cabs from '../home_pages/sub_pages/process_ecr/cabs/Cabs'
import Train from '../home_pages/sub_pages/process_ecr/train/Train'
import Visa from '../home_pages/sub_pages/process_ecr/visa/Visa'
import TravelInsurance from '../home_pages/sub_pages/process_ecr/travel_insurance/TravelInsurance'
import Passport from '../home_pages/sub_pages/process_ecr/passport/Passport'
import Cruise from '../home_pages/sub_pages/process_ecr/cruise/Cruise'
import User from '../home_pages/sub_pages/user_management/user/User'
import UserForm from '../home_pages/sub_pages/user_management/user/user_form/UserForm'
import Role from '../home_pages/sub_pages/user_management/role/Role'
import RoleForm from '../home_pages/sub_pages/user_management/role/role_form/RoleForm'
import Privilages from '../home_pages/sub_pages/user_management/privilages/Privilages'
import Clients from '../home_pages/sub_pages/master_settings/clients/Clients'
import ClientForm from '../home_pages/sub_pages/master_settings/clients/client_form/ClientForm'
import Suppliers from '../home_pages/sub_pages/master_settings/suppliers/Suppliers'
import SupplierForm from '../home_pages/sub_pages/master_settings/suppliers/supplier_form/SupplierForm'
import Services from '../home_pages/sub_pages/master_settings/services/Services'
import ServiceForm from '../home_pages/sub_pages/master_settings/services/service_form/ServiceForm'
import Locations from '../home_pages/sub_pages/master_settings/locations/Locations'
import LocationForm from '../home_pages/sub_pages/master_settings/locations/location_form/LocationForm'
import NotFound from '../not_found_pages/NotFound'
import { LocalStorageServices } from '../services/localStorageServices'
import FlightTicketForm from '../home_pages/sub_pages/process_ecr/flight_tickets/flight_ticket_form/FlightTicketForm'
import SalesReports from '../home_pages/sub_pages/process_ecr/Ecr_Reports/Sales_Report/SalesReports'
import AR_Apreports from '../home_pages/sub_pages/process_ecr/Ecr_Reports/AR_AP_Reports/AR_Apreports'
import Permissions from '../home_pages/sub_pages/user_management/privilages/Permissions'
import PackagesForm from '../home_pages/sub_pages/process_ecr/packages/packages_form/PackagesForm'
// import Hotels from '../home_pages/sub_pages/master_settings/hotels/Hotels'
import HotelOrResorts from '../home_pages/sub_pages/process_ecr/hotel_or_resorts/HotelOrResorts'
import HotelForm from '../home_pages/sub_pages/process_ecr/hotel_or_resorts/hotel_form/HotelForm'
import AuditLogs from '../home_pages/sub_pages/auditlogs/AuditLogs'
import GeneralSettings from '../home_pages/sub_pages/master_settings/general_settings/GeneralSettings'
import Notifications from '../home_pages/sub_pages/notifications/Notifications'
import CabsForm from '../home_pages/sub_pages/process_ecr/cabs/cabs_form/CabsForm'
import PassportForm from '../home_pages/sub_pages/process_ecr/passport/passport_form/PassportForm'
import ResetPassword from '../reset_password/ResetPassword'
import VisaForm from '../home_pages/sub_pages/process_ecr/visa/visa_form/VisaForm'
import InsuranceForm from '../home_pages/sub_pages/process_ecr/travel_insurance/insurance_form/InsuranceForm'
import Hotels from '../home_pages/sub_pages/process_ecr/hotel/Hotels'
import HotelBookingForm from '../home_pages/sub_pages/process_ecr/hotel/hotel_form/HotelBookingForm'
import TrainForm from '../home_pages/sub_pages/process_ecr/train/train_form/TrainForm'
import AginReport from '../home_pages/sub_pages/process_ecr/Ecr_Reports/aging_report/AginReport'
import TransactionReports from '../home_pages/sub_pages/process_ecr/Ecr_Reports/transaction_reports/TransactionReports'
import DashBoardList from '../home_pages/sub_pages/dashboard/dashboard_with_status/dashboard_with_status_list/DashBoardList'
import Airlines from '../home_pages/sub_pages/master_settings/general_settings/sub_components/airlines/Airlines'
function UiRouters() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Navigate to={LocalStorageServices.getAccessTokenLocalStorageData() ? `/ecrs_portal/${LocalStorageServices.getUserRole()}/dashboard` : `/ecrs_portal/login`} />} />
        <Route path='/ecrs_portal/login' element={<LoginPage />} />
        <Route path='/ecrs_portal/reset_password' element={<ResetPassword />} />
        <Route path='/ecrs_portal/:role/' element={<HomePage />}>
          <Route index element={<Navigate to={`dashboard`} />} />
          <Route path='dashboard' element={<Dashboard />} />
          <Route path='dashboard/list' element={<DashBoardList />} />

          <Route path='process_ecr'>
            <Route index element={<Navigate to={`flight_ticket`} />} />
            <Route path='flight_ticket' element={<FlightTickets />} />
            <Route path='create_new_flight_ticket' element={<FlightTicketForm />} />
            <Route path=':booking_id/amendment_flight_ticket' element={<FlightTicketForm />} />
            <Route path=':booking_id/update_flight_ticket' element={<FlightTicketForm />} />
            <Route path=':booking_id/view_flight_ticket' element={<FlightTicketForm />} />

            <Route path='packages' element={<Packages />} />
            <Route path='create_new_package' element={<PackagesForm />} />
            <Route path=':booking_id/view_package' element={<PackagesForm />} />
            <Route path=':booking_id/update_package' element={<PackagesForm />} />

            <Route path='cabs' element={<Cabs />} />
            <Route path='create_new_cab' element={<CabsForm />} />
            <Route path=':booking_id/view_cab' element={<CabsForm />} />
            <Route path=':booking_id/update_cab' element={<CabsForm />} />

            <Route path='hotels' element={<Hotels />} />
            <Route path='create_new_hotel_booking' element={<HotelBookingForm />} />
            <Route path=':booking_id/view_hotel_booking' element={<HotelBookingForm />} />
            <Route path=':booking_id/update_hotel_booking' element={<HotelBookingForm />} />
            {/* <Route path='hotels_or_resorts' element={<HotelOrResorts />} /> */}

            <Route path='visa' element={<Visa />} />
            <Route path='create_new_visa' element={<VisaForm />} />
            <Route path=':booking_id/view_visa' element={<VisaForm />} />
            <Route path=':booking_id/update_visa' element={<VisaForm />} />

            <Route path='travel_insurance' element={<TravelInsurance />} />
            <Route path='create_new_travel_insurance' element={<InsuranceForm />} />
            <Route path=':booking_id/view_travel_insurance' element={<InsuranceForm />} />
            <Route path=':booking_id/update_travel_insurance' element={<InsuranceForm />} />

            <Route path='train' element={<Train />} />
            <Route path='create_new_train' element={<TrainForm />} />
            <Route path=':booking_id/view_train' element={<TrainForm />} />
            <Route path=':booking_id/update_train' element={<TrainForm />} />


            <Route path='passport' element={<Passport />} />
            <Route path='create_new_passport' element={<PassportForm />} />
            <Route path=':booking_id/view_passport' element={<PassportForm />} />
            <Route path=':booking_id/update_passport' element={<PassportForm />} />

            <Route path='cruise' element={<Cruise />} />

            {/* <Route path='hotels_or_resorts' element={<HotelOrResorts />} />
            <Route path='create_new_hotel' element={<HotelForm />} />
            <Route path=':hotel_id/update_hotel' element={<HotelForm />} />
            <Route path=':hotel_id/view_hotel' element={<HotelForm />} /> */}

            <Route path="*" element={<Navigate to={"/not_found"} />} />
          </Route>
          <Route path='user_management'>
            <Route index element={<Navigate to={`users`} />} />

            <Route path='users' element={<User />} />
            <Route path='create_new_user' element={<UserForm />} />
            <Route path=':user_id/update_user' element={<UserForm />} />
            <Route path=':user_id/view_user' element={<UserForm />} />

            <Route path='roles' element={<Role />} />
            <Route path='create_new_role' element={<RoleForm />} />
            <Route path=':role_id/update_role' element={<RoleForm />} />
            <Route path=':role_id/view_role' element={<RoleForm />} />

            {/* ROUTE ADDED BY SREERAJ 24-10-24 FOR PRIVILLIAGE  */}
            <Route path='privileges' element={<Permissions />} />
            {/* <Route path='privilages' element={<Privilages />} /> */}
            <Route path="*" element={<Navigate to={"/not_found"} />} />
          </Route>
          <Route path='master_settings'>
            <Route index element={<Navigate to={`clients`} />} />

            <Route path='clients' element={<Clients />} />
            <Route path='create_new_client' element={<ClientForm />} />
            <Route path=':client_id/update_client' element={<ClientForm />} />
            <Route path=':client_id/view_client' element={<ClientForm />} />

            <Route path='suppliers' element={<Suppliers />} />
            <Route path='create_new_supplier' element={<SupplierForm />} />
            <Route path=':supplier_id/update_supplier' element={<SupplierForm />} />
            <Route path=':supplier_id/view_supplier' element={<SupplierForm />} />

            <Route path='services' element={<Services />} />
            <Route path='create_new_service' element={<ServiceForm />} />

            <Route path='locations' element={<Locations />} />
            <Route path='create_new_location' element={<LocationForm />} />
            <Route path=':location_id/update_location' element={<LocationForm />} />
            <Route path=':location_id/view_location' element={<LocationForm />} />

            <Route path='general_settings' element={<GeneralSettings />} />

            <Route path='airline_settings' element={<Airlines />} />


            <Route path='hotels_or_resorts' element={<HotelOrResorts />} />
            <Route path='create_new_hotel' element={<HotelForm />} />
            <Route path=':hotel_id/update_hotel' element={<HotelForm />} />
            <Route path=':hotel_id/view_hotel' element={<HotelForm />} />


            <Route path="*" element={<Navigate to={"/not_found"} />} />
          </Route>
          {/* New Route created by Najma on 01-10-2024 for Reports */}
          <Route path='ecr_reports'>
            <Route path='transaction_reports' element={<TransactionReports />} />
            <Route path='SalesReport' element={<SalesReports />} />
            <Route path='AR&AP_Report' element={<AR_Apreports />} />
            <Route path='AgingReport' element={<AginReport />} />

          </Route>
          {/* New Route created by Sreeraj on 06-11-2024 for Auditlogs */}
          <Route path='ecr_logs'>
            <Route path='Auditlogs' element={<AuditLogs />} />
          </Route>
          <Route path='notification' element={<Notifications />} />
        </Route>
        <Route path='/not_found' element={<NotFound />} />
        <Route path="/*" element={<Navigate to={"/not_found"} />} />
      </Routes>
    </>
  )
}

export default UiRouters