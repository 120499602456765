import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik';
import * as Yup from "yup";
// import './InsurancePassengerForm.css'
import CustomAutoCompleteDropdown from '../../../../../components/custom_auto_complete_dropdown/CustomAutoCompleteDropdown';
import CustomTextField from '../../../../../components/custom_text_field/CustomTextField';
import { get_cabin_types, get_passenger_type_lists, get_titles_lists } from '../../../../../api/lookupApis';
import { SuccessToast } from '../../../../../toast/toasts';
function InsurancePassengerForm({ totalPassenger, passengerOtherCounts, existingPassengerDetails, onClose, setPassengerDetails, updatePassengerDetails }) {
    const [passengerType, setPassengerType] = useState([])
    const [passengerTitle, setPassengerTitle] = useState([])
    const [totalCount, setTotalCount] = useState(0)
    const [showSuccessMessage, setShowSuccessMessage] = useState(false)

    useEffect(() => {
        getPassengerList()
        getPassengerTitle()
        if (existingPassengerDetails) {
            formik.setValues({
                ...formik.values, ...{
                    'passenger_type': existingPassengerDetails.type,
                    'title': existingPassengerDetails.title,
                    'name': existingPassengerDetails.full_name,
                    'ticket_number': existingPassengerDetails.ticket_number,
                    'base_fare': existingPassengerDetails.base_fare,
                    'supplier_amount': existingPassengerDetails.supplier_amount,
                    'customer_net': existingPassengerDetails.customer_net,
                    'service_fee': existingPassengerDetails.service_fee,
                    // 'FBPD_cabin_type': existingPassengerDetails.cabin_id
                }
            })
        }
        setTotalCount(passengerOtherCounts?.adult + passengerOtherCounts?.child + passengerOtherCounts?.infants)
    }, [existingPassengerDetails])

    const getPassengerList = () => {
        get_passenger_type_lists().then((response) => {
            let filterAdults = []
            let filterChild = []
            let filterInfants = []
            if (passengerOtherCounts?.adult > 0) {
                filterAdults = response?.data?.filter((item) => item.PTL_name === 'Adult')
            }
            if (passengerOtherCounts?.child > 0) {
                filterChild = response?.data?.filter((item) => item.PTL_name === 'Child')
            }
            if (passengerOtherCounts?.infants > 0) {
                filterInfants = response?.data?.filter((item) => item.PTL_name === 'Infant')
            }
            const data = [...filterAdults, ...filterChild, ...filterInfants].map((item) => {
                return {
                    label: item.PTL_name,
                    value: item.PTL_id,
                };
            });
            setPassengerType(data)
        }).catch((error) => {
            console.log(error)
        })
    }



    const getPassengerTitle = () => {
        get_titles_lists().then((response) => {
            const data = response?.data?.map((item) => {
                return {
                    label: item.TL_name,
                    value: item.TL_id,
                };
            });
            setPassengerTitle(data)
        }).catch((error) => {
            console.log(error)
        })
    }

    const formik = useFormik({
        initialValues: {
            'passenger_type': '',
            'title': '',
            'name': '',
            'ticket_number': '',
            'base_fare': 0,
            'supplier_amount': 0,
            'customer_net': 0,
            'service_fee': 0,
        },
        validationSchema: Yup.object({
            passenger_type: Yup.string()
                .required("Passenger type is required")
                .test(
                    'check-passenger-type',
                    'Passenger is required',
                    function (value) {
                        const errorMessage = (() => {
                            const { passenger_type } = this.parent;
                            const passengerCount = totalPassenger.filter(item => item.passenger_type == passenger_type).length;
                            const limit = passengerOtherCounts[passenger_type === '1' ? 'adult' : passenger_type === '2' ? 'child' : 'infants'];
                            // Determine the error message based on the condition
                            if (!passenger_type) {
                                return "Passenger type is required";
                            }

                            if (passengerCount === 0 && limit !== 0) {
                                return null
                            } else if (limit === 0) {
                                return "The passenger count limit is set to 0.";
                            } else if (passengerCount == limit && existingPassengerDetails?.type == passenger_type) {
                                return null
                            } else if (passengerCount >= limit) {
                                return "The number of passengers exceeds the allowed limit."
                            }

                            // Return null if no error
                            return null;
                        })();

                        // Return true if no error message is set, otherwise return false
                        return errorMessage === null ? true : this.createError({ message: errorMessage });
                    }
                ),
            title: Yup.string().required("Title is required"),
            name: Yup.string().required("Full name is required"),
            // ticket_number: Yup.string().required("Application number is required"),
            // FBPD_cabin_type: Yup.string().required("Cabin type is required")
        }),
        onSubmit: (values, { resetForm }) => {
            let value = JSON.parse(JSON.stringify(values))
            value['passenger_type_name'] = passengerType.find((type) =>
                type.value ===
                formik.values.type
            )?.label
            if (existingPassengerDetails) {
                value['index'] = existingPassengerDetails.index
                updatePassengerDetails(value)
            } else {
                setPassengerDetails(value)
            }
            if (existingPassengerDetails) {
                setTotalCount(0)
                onClose(false)
            } else {
                showSuccessMessageTimeOut()
                SuccessToast("Sucessfully added new passenger")
                resetForm()
            }

        }
    })

    function showSuccessMessageTimeOut() {
        setShowSuccessMessage(true)
        setTimeout(() => {
            setShowSuccessMessage(false)
        }, 2000)
    }
    return (
        <div className='flight_ticket_passenger_form_super_container'>
            <form id="InsurancePassengerForm" onSubmit={formik.handleSubmit}>
                {/* <div className='passenger_count_exceeded_message'>
                    {!existingPassengerDetails && totalPassenger.length === totalCount && (
                        <p>The passenger count has been exceeded</p>
                    )}
                </div> */}
                {showSuccessMessage && (
                    <div className='passenger_added_success_message'>
                        <p>Sucessfully added a passenger </p>
                    </div>
                )}
                <div className='flight_ticket_passenger_form_main_container'>
                    <p className="flight_ticket_passenger_form_legend">Passenger Details</p>
                    <div>
                        <div className='flight_ticket_passenger_form_sub_container_0'>

                            <div>
                                <p className='flight_ticket_passenger_form_label'>Title<span className='label_required_remark'>*</span></p>
                                <CustomAutoCompleteDropdown
                                    autoFocus={true}
                                    isDisabled={!existingPassengerDetails && totalPassenger.length === totalCount}
                                    name="title"
                                    placeholder={"Select a title"}
                                    options={passengerTitle}
                                    onChange={(event, newValue) => {
                                        formik.setFieldValue(
                                            "title",
                                            newValue ? newValue.value : ""
                                        );
                                    }}
                                    onBlur={formik.handleBlur}
                                    value={
                                        passengerTitle.find((type) =>
                                            type.value ===
                                            formik.values.title
                                        ) || null
                                    }
                                    error={
                                        formik.touched.title &&
                                        Boolean(formik.errors.title)
                                    }
                                    helperText={
                                        formik.touched.title &&
                                        formik.errors.title
                                    }
                                />
                            </div>

                            <div>
                                <p className='flight_ticket_passenger_form_label'>Full Name<span className='label_required_remark'>*</span></p>
                                <CustomTextField
                                    isDisabled={!existingPassengerDetails && totalPassenger.length === totalCount}
                                    name="name"
                                    placeholder={"Enter full name"}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.name}
                                    error={formik.touched.name &&
                                        Boolean(formik.errors.name)
                                    }
                                    helperText={
                                        formik.touched.name && formik.errors.name
                                    }
                                />
                            </div>

                            <div>
                                <p className='flight_ticket_passenger_form_label'>Passenger Type<span className='label_required_remark'>*</span></p>
                                <CustomAutoCompleteDropdown
                                    isDisabled={!existingPassengerDetails && totalPassenger.length === totalCount}
                                    name="passenger_type"
                                    placeholder={"Select a passenger type"}
                                    options={passengerType}
                                    onChange={(event, newValue) => {
                                        formik.setFieldValue(
                                            "passenger_type",
                                            newValue ? newValue.value : ""
                                        );
                                    }}
                                    onBlur={formik.handleBlur}
                                    value={
                                        passengerType.find((type) =>
                                            type.value ===
                                            formik.values.passenger_type
                                        ) || null
                                    }
                                    error={
                                        formik.touched.passenger_type &&
                                        Boolean(formik.errors.passenger_type)
                                    }
                                    helperText={
                                        formik.touched.passenger_type &&
                                        formik.errors.passenger_type
                                    }
                                />
                            </div>


                            <div>
                                <p className='flight_ticket_passenger_form_label'>Application Number</p>
                                <CustomTextField
                                    isDisabled={!existingPassengerDetails && totalPassenger.length === totalCount}
                                    name="ticket_number"
                                    placeholder={"Enter Application  number"}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.ticket_number}
                                    // error={formik.touched.ticket_number &&
                                    //     Boolean(formik.errors.ticket_number)
                                    // }
                                    helperText={
                                        formik.touched.ticket_number && formik.errors.ticket_number
                                    }
                                />
                            </div>

                        </div>

                        <div className='flight_ticket_passenger_form_sub_container_0'>
                            <div>
                                <p className='flight_ticket_passenger_form_label'>Base Fare</p>
                                <CustomTextField
                                    isDisabled={!existingPassengerDetails && totalPassenger.length === totalCount}
                                    inputProps={{ inputProps: { min: 0, step: ".01" } }}
                                    type={'number'}
                                    name="base_fare"
                                    placeholder={"Enter base fare"}
                                    onChange={(e) => {
                                        formik.handleChange(e)
                                        const baseFare = (e.target.value !== '' ? Number(e.target.value) : 0)
                                        const serviceFee = Number(formik.values?.service_fee) || 0; // Default to 0 if undefined or null
                                        const amount = baseFare + serviceFee;
                                        formik.setFieldValue("supplier_amount", baseFare.toFixed(2));
                                        formik.setFieldValue("customer_net", amount?.toFixed(2))

                                    }}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.base_fare}
                                    error={formik.touched.base_fare &&
                                        Boolean(formik.errors.base_fare)
                                    }
                                    helperText={
                                        formik.touched.base_fare && formik.errors.base_fare
                                    }
                                />
                            </div>

                            <div>
                                <p className='flight_ticket_passenger_form_label'>Supplier Amount</p>
                                <CustomTextField
                                    isDisabled={true}
                                    type={'number'}
                                    inputProps={{ inputProps: { min: 0, step: ".01" } }}
                                    name="supplier_amount"
                                    placeholder={"Enter supplier amount"}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.supplier_amount}
                                    error={formik.touched.supplier_amount &&
                                        Boolean(formik.errors.supplier_amount)
                                    }
                                    helperText={
                                        formik.touched.supplier_amount && formik.errors.supplier_amount
                                    }
                                />
                            </div>

                            <div>
                                <p className='flight_ticket_passenger_form_label'>Service Fee</p>
                                <CustomTextField
                                    isDisabled={!existingPassengerDetails && totalPassenger.length === totalCount}
                                    name="service_fee"
                                    type={'number'}
                                    inputProps={{ inputProps: { min: 0, step: ".01" } }}
                                    placeholder={"Enter service fee"}
                                    onChange={(e) => {
                                        formik.handleChange(e)
                                        let amount = (e.target.value !== '' ? Number(e.target.value) : 0) + Number(formik.values.supplier_amount)
                                        formik.setFieldValue("customer_net", amount.toFixed(2))
                                    }}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.service_fee}
                                    error={formik.touched.service_fee &&
                                        Boolean(formik.errors.service_fee)
                                    }
                                    helperText={
                                        formik.touched.service_fee && formik.errors.service_fee
                                    }
                                />
                            </div>

                            <div>
                                <p className='flight_ticket_passenger_form_label'>Customer Net</p>
                                <CustomTextField
                                    isDisabled={true}
                                    name="customer_net"
                                    type={'number'}
                                    inputProps={{ inputProps: { min: 0, step: ".01" } }}
                                    placeholder={"Enter customer net"}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.customer_net}
                                    error={formik.touched.customer_net &&
                                        Boolean(formik.errors.customer_net)
                                    }
                                    helperText={
                                        formik.touched.customer_net && formik.errors.customer_net
                                    }
                                />
                            </div>
                        </div>

                    </div>
                </div>
                <div className='flight_ticket_passenger_form_action_container'>
                    {!existingPassengerDetails && totalPassenger.length === totalCount ? <></> :
                        <div className='flight_ticket_passenger_form_single_row_alignment'>
                            {!existingPassengerDetails && (<button className='flight_ticket_passenger_form_reset_button' type='button'>Reset</button>)}

                            <button
                                className='flight_ticket_passenger_form_save_button'
                                type="submit"
                                form="InsurancePassengerForm">{!existingPassengerDetails ? "Save" : "Update"}</button>
                        </div>
                    }

                </div>
            </form>
        </div>
    )
}

export default InsurancePassengerForm