import React, { useEffect, useState } from 'react'
import './sliderMenuStyle.css'
import las_logo from '../../../assets/las_logo_white.png'
import SliderMenuComponent from '../../../components/slider_menu_component/SliderMenuComponent'
import DashboardLogo from '../../../assets/dashboard_logo.png'
import ProcessECR from '../../../assets/process_ecr_logo.png'
import Payments from '../../../assets/payment_icon.png'
import Address_book from '../../../assets/address_book_icon.png'
import UserManagement from '../../../assets/User_management_icon.png'
import Reports_icon from '../../../assets/reports_icon.png'
import Settings_icon from '../../../assets/setting_icon.png'
import Las_logo_mobile from '../../../assets/las_logo_only.png'
import notification_icon from '../../../assets/notification_icon.png'
import { useSelector } from 'react-redux'
import { getPrivilegesDetailsLocalStorageData, LocalStorageServices } from '../../../services/localStorageServices'
import { IoMdNotificationsOutline } from "react-icons/io";
function SliderMenu() {
  let baseRouteUrl = `/ecrs_portal/${LocalStorageServices.getUserRole()}/`
  const privilage_details = useSelector((state) => state.commonStore.privilage_Details)
  useState(() => {
    const user_details = LocalStorageServices.getUserDetailsLocalStorageData()
    let ParsedUserDetails = user_details ? JSON.parse(user_details) : user_details
    // console.log(ParsedUserDetails)
    if (ParsedUserDetails) {
      // const newUrl = baseRouteUrl.replace(':role', ParsedUserDetails?.role?.role_name)
      // baseRouteUrl = newUrl
    }

  }, [])
  const isCollapsed = useSelector((state) => state.commonStore.isCollapsed)

  // Fetch privileges data once
  const privileges = getPrivilegesDetailsLocalStorageData();

  //---------------------------------PROCESS ECR START------------------------------------------------------
  // Check if Process ECR is available
  const isShowProcessEcr = privileges?.some(item =>
    item.module_name === "Process ECR" &&
    ((item.submodules?.["Flight Tickets"]?.privileges?.some(priv => priv?.name === 'List')) ||
      (item.submodules?.["Packages"]?.privileges?.some(priv => priv?.name === 'List')) ||
      (item.submodules?.["Cabs"]?.privileges?.some(priv => priv?.name === 'List')) ||
      // (item.submodules?.["Hotel/Resorts"]?.privileges?.some(priv => priv?.name === 'List')) ||
      (item.submodules?.["Train"]?.privileges?.some(priv => priv?.name === 'List')) ||
      (item.submodules?.["Passport"]?.privileges?.some(priv => priv?.name === 'List')) ||
      (item.submodules?.["Hotel"]?.privileges?.some(priv => priv?.name === 'List')) ||
      (item.submodules?.["Insurance"]?.privileges?.some(priv => priv?.name === 'List')) ||
      (item.submodules?.["Visa"]?.privileges?.some(priv => priv?.name === 'List')))
  );
  // Check if Flight Tickets has List permission
  const isShowProcessEcr_Flight_Tickets = privileges?.some(item =>
    item.module_name === "Process ECR" &&
    item.submodules?.["Flight Tickets"]?.privileges?.some(priv => priv?.name === 'List')
  );

  // Check if Flight Tickets has List permission
  const isShowProcessEcr_packages = privileges?.some(item =>
    item.module_name === "Process ECR" &&
    item.submodules?.["Packages"]?.privileges?.some(priv => priv?.name === 'List')
  );


  // Check if Flight Hotel Booking as List permission
  const isShowProcessEcr_Hotels_Booking = privileges?.some(item =>
    item.module_name === "Process ECR" &&
    item.submodules?.["Hotel Booking"]?.privileges?.some(priv => priv?.name === 'List')
  );
  // Check if Cabs has List permission
  const isShowProcessEcr_Cabs = privileges?.some(item =>
    item.module_name === "Process ECR" &&
    item.submodules?.["Cabs"]?.privileges?.some(priv => priv?.name === 'List')
  );

  // Check if Cabs has List permission
  const isShowProcessEcr_Visa = privileges?.some(item =>
    item.module_name === "Process ECR" &&
    item.submodules?.["Visa"]?.privileges?.some(priv => priv?.name === 'List')
  );

  // Check if Cabs has Passport permission
  const isShowProcessEcr_Passport = privileges?.some(item =>
    item.module_name === "Process ECR" &&
    item.submodules?.["Passport"]?.privileges?.some(priv => priv?.name === 'List')
  );

  // Check if Insurance has List permission
  const isShowProcessEcr_Insurance = privileges?.some(item =>
    item.module_name === "Process ECR" &&
    item.submodules?.["Insurance"]?.privileges?.some(priv => priv?.name === 'List')
  );

  // Check if Train Ticket  has List permission
  const isShowProcessEcr_Train = privileges?.some(item =>
    item.module_name === "Process ECR" &&
    item.submodules?.["Train"]?.privileges?.some(priv => priv?.name === 'List')
  );

  // Check if Hotel   has List permission
  const isShowProcessEcr_Hotel = privileges?.some(item =>
    item.module_name === "Process ECR" &&
    item.submodules?.["Hotel"]?.privileges?.some(priv => priv?.name === 'List')
  );
  //----------------------------------PROCESS ECR END----------------------------------------------------//-------------------

  //----------------------------------USER MANAGEMENT START----------------------------------------------------//-------------------

  // Check if User  Management  is available
  const isShowUserManagement = privileges?.some(item =>
    item.module_name === "User Management" &&
    ((item.submodules?.["User"]?.privileges?.some(priv => priv?.name === 'List')) ||
      (item.submodules?.["Role"]?.privileges?.some(priv => priv?.name === 'List')) ||
      (item.submodules?.["Privileges"])?.privileges?.some(priv => priv?.name === 'List'))
  );
  //USER LIST
  const isShowUserManagementList = privileges?.some(item =>
    item.module_name === "User Management" &&
    item.submodules?.["User"]?.privileges?.some(priv => priv?.name === 'List')
  );

  //ROLE LIST
  const isShowRoleManagementList = privileges?.some(item =>
    item.module_name === "User Management" &&
    item.submodules?.["Role"]?.privileges?.some(priv => priv?.name === 'List')
  );
  //Privileges LIST
  const isShowPrivilegeManagementList = privileges?.some(item =>
    item.module_name === "User Management" &&
    item.submodules?.["Privileges"]?.privileges?.some(priv => priv?.name === 'List')
  );
  //----------------------------------USER MANAGEMENT END----------------------------------------------------//-------------------
  //---------------------------------Reports START----------------------------------------------------//-------------------


  const isShowReports = privileges?.some(item =>
    item.module_name === "Reports" &&
    ((item.submodules?.["ECR Report"]?.privileges?.some(priv => priv?.name === 'List')) ||
      (item.submodules?.["Sales"]?.privileges?.some(priv => priv?.name === 'List')) ||
      (item.submodules?.["Aging Report"]?.privileges?.some(priv => priv?.name === 'List'))
      ||
      (item.submodules?.["Transaction Report"]?.privileges?.some(priv => priv?.name === 'List'))

    )
  );

  const Show_Reports_ECR_Report = privileges?.some(item =>
    item.module_name === "Reports" &&
    item.submodules?.["ECR Report"]?.privileges?.some(priv => priv?.name === 'List')
  );

  //FOR AGING REPORT LIST SHOW
  const Show_Aging_Report = privileges?.some(item =>
    item.module_name === "Reports" &&
    item.submodules?.["Aging Report"]?.privileges?.some(priv => priv?.name === 'List')
  );

  const Show_Reports_Sales = privileges?.some(item =>
    item.module_name === "Reports" &&
    item.submodules?.["Sales"]?.privileges?.some(priv => priv?.name === 'List')
  );
  const Show_Transaction_Report = privileges?.some(item =>
    item.module_name === "Reports" &&
    item.submodules?.["Transaction Report"]?.privileges?.some(priv => priv?.name === 'List')
  );
  //----------------------------------Reports END----------------------------------------------------//-------------------

  //---------------------------------Master Settings START----------------------------------------------------//-------------------
  const isShowMasterSettings = privileges?.some(item =>
    item.module_name === "Master Settings" &&
    ((item.submodules?.["Clients"]?.privileges?.some(priv => priv?.name === 'List')) ||
      (item.submodules?.["Suppliers"]?.privileges?.some(priv => priv?.name === 'List'))) ||
    (item.submodules?.["Services"]?.privileges?.some(priv => priv?.name === 'List')) ||
    (item.submodules?.["Locations"]?.privileges?.some(priv => priv?.name === 'List')) ||
    (item.submodules?.["Hotel/Resorts"]?.privileges?.some(priv => priv?.name === 'List')) ||
    (item.submodules?.["General Settings"]?.privileges?.some(priv => priv?.name === 'List')) ||
    (item.submodules?.["Airline Settings"]?.privileges?.some(priv => priv?.name === 'List'))

  );
  const Show_Master_Setting_Clients = privileges?.some(item =>
    item.module_name === "Master Settings" &&
    item.submodules?.["Clients"]?.privileges?.some(priv => priv?.name === 'List')
  );

  const Show_Master_Setting_Suppliers = privileges?.some(item =>
    item.module_name === "Master Settings" &&
    item.submodules?.["Suppliers"]?.privileges?.some(priv => priv?.name === 'List')
  );

  const Show_Master_Setting_Services = privileges?.some(item =>
    item.module_name === "Master Settings" &&
    item.submodules?.["Services"]?.privileges?.some(priv => priv?.name === 'List')
  );

  const Show_Master_Setting_Locations = privileges?.some(item =>
    item.module_name === "Master Settings" &&
    item.submodules?.["Locations"]?.privileges?.some(priv => priv?.name === 'List')
  );
  const Show_Master_Setting_General = privileges?.some(item =>
    item.module_name === "Master Settings" &&
    item.submodules?.["General Settings"]?.privileges?.some(priv => priv?.name === 'List')
  );

  const Show_Master_Setting_Airline = privileges?.some(item =>
    item.module_name === "Master Settings" &&
    item.submodules?.["Airline Settings"]?.privileges?.some(priv => priv?.name === 'List')
  );
  // Check if Flight Hotel/Resorts has List permission
  const isShow_Hotels_Resorts = privileges?.some(item =>
    item.module_name === "Master Settings" &&
    item.submodules?.["Hotel/Resorts"]?.privileges?.some(priv => priv?.name === 'List')
  );
  //----------------------------------Master Settings END----------------------------------------------------//-------------------

  //---------------------------------AuditLog START----------------------------------------------------//-------------------

  const isShowAuditLog = privileges?.some(item =>
    item.module_name === "AuditLogs" &&
    ((item.submodules?.["AuditLogs"]?.privileges?.some(priv => priv?.name === 'List'))))
  //----------------------------------AuditLog END----------------------------------------------------//-------------------

  return (
    <div className='home_page_slider_menus_main_container' style={{ width: isCollapsed ? 75 : 250, overflow: isCollapsed ? 'visible' : 'auto' }}>
      <div className='home_page_slider_sub_image_container' >
        {isCollapsed ? <img src={Las_logo_mobile} className='slider_las_collapsed_icon' /> : <img src={las_logo} className='slider_las_icon' />}
      </div>
      <div className='home_page_menu_container'>
        <SliderMenuComponent icon={DashboardLogo} text={'Dashboard'} route={baseRouteUrl + 'dashboard'} />

        {privileges?.length > 0 && <>
          {isShowProcessEcr && <SliderMenuComponent icon={ProcessECR} text={'Process ECR'}
            subroute={[
              { text: "Flight Ticket", route: baseRouteUrl + 'process_ecr/flight_ticket', otherRoutes: ['create_new_flight_ticket', 'view_flight_ticket', 'update_flight_ticket'], isPermitted: isShowProcessEcr_Flight_Tickets },
              { text: "Packages", route: baseRouteUrl + 'process_ecr/packages', isPermitted: isShowProcessEcr_packages, otherRoutes: ['create_new_package', 'update_package', 'view_package'], },
              { text: "Hotel Booking", route: baseRouteUrl + 'process_ecr/hotels', otherRoutes: ['create_new_hotel_booking', 'update_hotel', 'view_hotel'], isPermitted: isShowProcessEcr_Hotel },
              { text: "Cabs", route: baseRouteUrl + 'process_ecr/cabs', isPermitted: isShowProcessEcr_Cabs, otherRoutes: ['create_new_cab', 'view_cab', 'update_cab'], },
              { text: "Passport", route: baseRouteUrl + 'process_ecr/passport', isPermitted: isShowProcessEcr_Passport, otherRoutes: ['create_new_passport', 'update_passport', 'view_passport'], },
              { text: "Visa", route: baseRouteUrl + 'process_ecr/visa', isPermitted: isShowProcessEcr_Visa, otherRoutes: ['create_new_visa', 'update_visa', 'view_visa'], },
              { text: "Travel Insurance", route: baseRouteUrl + 'process_ecr/travel_insurance', isPermitted: isShowProcessEcr_Insurance, otherRoutes: ['create_new_travel_insurance', 'view_travel_insurance', 'update_travel_insurance'], },
              { text: "Train", route: baseRouteUrl + 'process_ecr/train', isPermitted: isShowProcessEcr_Train, otherRoutes: ['create_new_train', 'update_train', 'view_train'], },

              // { text: "Cruise", route: baseRouteUrl + 'process_ecr/cruise' },
            ]}
          />}
          {/* <SliderMenuComponent icon={Payments} text={'Payments'} route={'/DASHBOARD'} /> */}
          {isShowUserManagement && <SliderMenuComponent icon={UserManagement} text={'User Management'} subroute={[
            { text: "User", route: baseRouteUrl + 'user_management/users', otherRoutes: ['create_new_user', 'update_user', 'view_user'], isPermitted: isShowUserManagementList },
            { text: "Role", route: baseRouteUrl + 'user_management/roles', otherRoutes: ['create_new_role', 'update_role', 'view_role'], isPermitted: isShowRoleManagementList },
            { text: "Privileges", route: baseRouteUrl + 'user_management/privileges', isPermitted: isShowPrivilegeManagementList }
          ]} />}
          {isShowReports && <SliderMenuComponent icon={Reports_icon} text={'Reports'} subroute={[
            { text: "ECR Report" },
            { text: "Sales", route: baseRouteUrl + 'ecr_reports/SalesReport', isPermitted: Show_Reports_Sales },//changed by najma on 01-10-2024
            { text: "Aging", route: baseRouteUrl + 'ecr_reports/AgingReport', isPermitted: Show_Aging_Report },//created by sreeraj on 05-12-2024
            { text: "Transactions", route: baseRouteUrl + 'ecr_reports/transaction_reports', isPermitted: Show_Transaction_Report },//changed by sajin on 05-12-2024            // { text: "AR & AP Report", route: baseRouteUrl + 'ecr_reports/AR&AP_Report', isPermitted: Show_Reports_ECR_Report },
            // { text: "Payment Report" },
            // { text: "Tax Report-B2B" },
            // { text: "Tax Report-B2C" },
          ]} />}
          {isShowMasterSettings && <SliderMenuComponent icon={Settings_icon} text={'Master Settings'} subroute={[
            { text: "Client", route: baseRouteUrl + 'master_settings/clients', otherRoutes: ['create_new_client', 'update_client', 'view_client'], isPermitted: Show_Master_Setting_Clients },
            { text: "Supplier", route: baseRouteUrl + 'master_settings/suppliers', otherRoutes: ['create_new_supplier', 'update_supplier', 'view_supplier'], isPermitted: Show_Master_Setting_Suppliers },
            // { text: "Services", route: baseRouteUrl + 'master_settings/services', otherRoutes: ['create_new_service'] },
            { text: "Locations", route: baseRouteUrl + 'master_settings/locations', otherRoutes: ['create_new_location', 'update_location', 'view_location'], isPermitted: Show_Master_Setting_Locations },
            // { text: "Hotels", route: baseRouteUrl + 'master_settings/hotels', otherRoutes: ['create_new_hotel', 'update_hotel', 'view_hotel'], isPermitted: true },
            { text: "Hotel / Resorts", route: baseRouteUrl + 'master_settings/hotels_or_resorts', otherRoutes: ['create_new_hotel', 'update_hotel', 'view_hotel'], isPermitted: isShow_Hotels_Resorts },

            { text: "General Setting", route: baseRouteUrl + 'master_settings/general_settings', isPermitted: Show_Master_Setting_General },
            { text: "Airline Setting", route: baseRouteUrl + 'master_settings/airline_settings', isPermitted: Show_Master_Setting_Airline },

            // { text: "Tax - TCS" },

          ]}
          />}
          {isShowAuditLog && <SliderMenuComponent icon={Reports_icon} text={'Audit Logs'} subroute={[
            { text: "Audit Logs", route: baseRouteUrl + 'ecr_logs/Auditlogs', isPermitted: isShowAuditLog },//added by sreeraj on 08-11-2024
          ]} />}

          <SliderMenuComponent icon={notification_icon} text={'Notification'} route={baseRouteUrl + 'notification'} />

        </>}
      </div>
    </div>
  )
}

export default SliderMenu