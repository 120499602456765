import React from 'react';
import { Badge } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { LocalStorageServices } from '../../../../../../services/localStorageServices';

const PendingItemCard = ({ label, count, status, isBtnShow = false }) => {

    const navigate = useNavigate()
    const baseRouteUrl = `/ecrs_portal/${LocalStorageServices.getUserRole()}/dashboard/list`

    const handleCardClick = (status) => {
        navigate(baseRouteUrl, { state: { status } })
    };

    return (
        <div
            onClick={() => isBtnShow ? handleCardClick(status) : ''}
            style={{
                p: 2,
                display: 'flex',
                alignItems: 'center',
                borderRadius: '12px',
                backgroundColor: '#f7f9fc',
                border: '1px solid #e0e0e0',
                // maxWidth: 300,
                cursor: isBtnShow ? 'pointer' : 'not-allowed',

            }}
        >

            <div

                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '1.9rem',
                    padding: '1.3rem',
                    minWidth: 100,

                }}>
                <span style={{ fontWeight: '600', color: `#074D4D`, fontFamily: '"Jost", sans-serif' }}>
                    {label}
                </span>
                <Badge
                    badgeContent={count}
                    color="primary"
                    showZero
                    sx={{
                        '& .MuiBadge-badge': {
                            fontSize: '1rem',
                            height: '24px',
                            minWidth: '24px',
                            borderRadius: '50%',
                            backgroundColor: 'red',
                            color: '#fff', fontWeight: '800',
                        },
                    }}
                />
            </div>
        </div>
    );
};

export default PendingItemCard;
