
import React, { useEffect, useState } from 'react'
import "./DashBoardListStyle.css"
import { useLocation, useNavigate } from 'react-router-dom';
import { LocalStorageServices } from '../../../../../services/localStorageServices';
import { getServicesByStatus } from '../../../../../api/dashboardApis';
function DashBoardList({ update_notification_read, viewAllAction, is_border }) {
    const location = useLocation();
    const { status } = location.state || {}; // Use optional chaining to avoid errors if state is undefined

    let baseRouteUrl = `/ecrs_portal/${LocalStorageServices.getUserRole()}/`
    const navigate = useNavigate()

    const [notificationList, setNotificationList] = useState([])

    const fetchServicesByStatus = async (payload) => {
        try {
            const responseServicesStatus = await getServicesByStatus(payload); // Assuming it expects a payload
            if (responseServicesStatus?.data?.status) {
                setNotificationList(responseServicesStatus?.data?.data)
            } else {
                setNotificationList([])
            }
        } catch (error) {
            setNotificationList([])
            console.error("Error fetching services by status:", error);
        }
    };

    useEffect(() => {
        if (status) {
            const payload = { status };
            fetchServicesByStatus(payload)
        }

    }, [location])


    const navigate_to_module = (item, module, filter_data) => {
        // dispatch(updateNotificationRedirect({module,filter_data}))
        if (module === "Package") {
            navigate(baseRouteUrl + `process_ecr/${item.booking_id}/view_package?is_redirect=true`)
        } else if (module === "Flight") {
            navigate(baseRouteUrl + `process_ecr/${item.booking_id}/view_flight_ticket?is_redirect=true`)
        } else if (module === "Hotel") {
            navigate(baseRouteUrl + `process_ecr/${item.booking_id}/view_hotel_booking?is_redirect=true`)
        } else if (module === "Cab") {
            navigate(baseRouteUrl + `process_ecr/${item.booking_id}/view_cab?is_redirect=true`)
        } else if (module === "Passport") {
            navigate(baseRouteUrl + `process_ecr/${item.booking_id}/view_passport?is_redirect=true`)
        } else if (module === "Visa") {
            navigate(baseRouteUrl + `process_ecr/${item.booking_id}/view_visa?is_redirect=true`)
        } else if (module === "Insurance") {
            navigate(baseRouteUrl + `process_ecr/${item.booking_id}/view_travel_insurance?is_redirect=true`)
        } else if (module === "Train") {
            navigate(baseRouteUrl + `process_ecr/${item.booking_id}/view_train?is_redirect=true`)
        }
    }

    return (
        <div className='header_notification_listing_main_container scrollable-div' style={{ border: is_border ? '1px solid #eaeaea' : 'none' }}>
            {notificationList && notificationList.length > 0 ?
                <div >
                    {notificationList.map((item) => (
                        <div className='header_notification_list_sub_container'
                            onClick={() => navigate_to_module(item, item?.type, item?.module_number)}

                        >

                            <div className='header_notification_list_flex'>
                                <p className='header_notification_message'>{item?.type}</p>
                                <p>--</p>
                                <p className='header_notification_user_name'>{item?.module_number}</p>

                            </div>

                            <div className='header_notification_list_flex'>
                                <p className='header_notification_message'>{`Client`}</p>
                                <p>--</p>
                                <p className='header_notification_message'>{item.get_client?.CI_name}</p>

                            </div>

                            <div className='header_notification_user_details_main_container_container'>
                            </div>

                        </div>
                    ))}
                </div> : <div className='header_notification_list_sub_container'>
                    <p className='header_notification_not_found'>No data found</p>
                </div>
            }
        </div>
    )
}

export default DashBoardList