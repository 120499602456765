import React, { useEffect, useState } from 'react'
import { IoAddOutline } from "react-icons/io5";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { BiEditAlt } from "react-icons/bi";
import CustomTable from '../../../../../components/custom_table/CustomTable';
// import './TrainPassengerTable.css'
import CustomModal from '../../../../../components/custom_modal/CustomModal';
import { IconButton } from '@mui/material';
import { IoIosCloseCircleOutline } from "react-icons/io";
import { get_cabin_types, get_passenger_type_lists, get_titles_lists } from '../../../../../api/lookupApis';
import { GoDotFill } from "react-icons/go";
import CustomExpandableDataTable from '../../../../../components/custom_expandable_table/CustomExpandableDataTable';
import TrainPassengerForm from '../train_passenger_form/TrainPassengerForm';
function TrainPassengerTable({ isDisabled, passengerCount, passengerOtherCount, passengerList, setPassengerList, updatePassengerDetails, passengerDelete }) {

    const [open, setOpen] = useState(false);
    const [passengerDetails, setPassengerDetails] = useState(null)
    const [passengerType, setPassengerType] = useState([])
    const [passengerTitle, setPassengerTitle] = useState([])

    useEffect(() => {

        getPassengerList()
        getPassengerTitle()

    }, [passengerList])
    const getPassengerList = () => {
        get_passenger_type_lists().then((response) => {
            const data = response?.data?.map((item) => {
                return {
                    label: item.PTL_name,
                    value: item.PTL_id,
                };
            });
            setPassengerType(data)
        }).catch((error) => {
            console.log(error)
        })
    }

    const getPassengerTitle = () => {
        get_titles_lists().then((response) => {
            const data = response?.data?.map((item) => {
                return {
                    label: item.TL_name,
                    value: item.TL_id,
                };
            });
            setPassengerTitle(data)
        }).catch((error) => {
            console.log(error)
        })
    }

    const Columns = [
        {
            title: "",
            dataIndex: "Expand",
            key: "expand",

        },
        {
            title: "Sl No",
            dataIndex: "key",
            key: "key",

        },
        {
            title: "Title",
            dataIndex: "title_name",
            key: "title_name",
        },
        {
            title: "Full Name",
            dataIndex: "full_name",
            key: "full_name",
        },
        {
            title: "Type",
            dataIndex: "type_name",
            key: "type_name",
        },
        {
            title: "Ticket Number",
            dataIndex: "ticket_number",
            key: "ticket_number",
        },
        {
            title: "Base Fare",
            dataIndex: "base_fare",
            key: "base_fare",
        },

        {
            title: "Supplier Amount",
            dataIndex: "supplier_amount",
            key: "supplier_amount",
        },
        {
            title: "Service Fee",
            dataIndex: "service_fee",
            key: "service_fee",
            isServiceFeeColumn: true,
        },
        {
            title: "Customer Net",
            dataIndex: "customer_net",
            key: "customer_net",
        },
        {
            title: "Actions",
            dataIndex: "actions",
            key: "actions",
            hide: isDisabled,
            action: (row) => (
                <div style={{ display: 'flex', alignItems: 'center', 'gap': 5 }}>
                    <BiEditAlt className='flight_ticket_passenger_table_action_edit' onClick={() => {
                        setPassengerDetails(row)
                        setOpen(true)
                    }} />
                    {/* {!isUpdate && ( */}
                    <MdOutlineDeleteOutline className='flight_ticket_passenger_table_action_delete' onClick={() => {
                        passengerDelete(row.index)
                    }} />
                    {/* )
                    } */}
                </div>
            ),
        },
    ];
  
    const handleClose = (is_close) => {
        if (!is_close) {
            setPassengerDetails(null)
        }
        setOpen(is_close);
    };
    const set_passenger_details = (details) => {
        setPassengerList(details)
    }
    const update_passenger_details = (details) => {
        updatePassengerDetails(details)
    }
    const datas = () => {
        const result =
            passengerList &&
            passengerList?.map((item, index) => {
                const passengerTypeItem = passengerType.find(pt => pt.value == item.passenger_type)?.label;
                const passengerTitleLable = passengerTitle.find(pt => pt.value == item.title)?.label;
                return {
                    key: index + 1,
                    index: index,
                    type: item.passenger_type,
                    title: item.title,
                    title_name: passengerTitleLable,
                    ticket_number: item.ticket_number,
                    full_name: item.name,
                    base_fare: Number(item.base_fare),
                    service_fee: Number(item.service_fee),
                    supplier_amount: Number(item.supplier_amount),
                    customer_net: Number(item.customer_net),
                    type_name: passengerTypeItem,
                };
            });
        return result;
    };
    
    //function to calculate the service fee , added by evangelin
    const calculateTotalServiceFee = (passengerList) => {
        return passengerList?.reduce((total, passenger) => {
            return total + Number(passenger.service_fee || 0); // Safely handle missing or invalid values
        }, 0);
    };
    const totalServiceFee = calculateTotalServiceFee(passengerList);

    return (
        <div>
            <CustomModal
                maxWidth={"1500px"}
                open={open}
                handleClose={handleClose}
                close={<IconButton
                    aria-label="close"
                    onClick={() => {
                        handleClose(false)
                    }}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                > <IoIosCloseCircleOutline />
                </IconButton>}
                body={
                    <TrainPassengerForm totalPassenger={passengerList} passengerOtherCounts={passengerOtherCount} existingPassengerDetails={passengerDetails} onClose={handleClose} setPassengerDetails={set_passenger_details} updatePassengerDetails={update_passenger_details} />
                }
                title={!passengerDetails ? "Add Passenger" : "Update Passenger"}
            />
            <div className='flight_ticket_passenger_add_passenger_action_container' >
                <div>
                    {true && passengerCount != 0 && passengerCount > 0 && passengerList.length != passengerCount && (
                        <div className='flight_ticket_passenger_table_header_action_container' tabindex="0" onClick={() => {
                            setOpen(true)
                        }}>
                            <IoAddOutline className='flight_ticket_passenger_table_action_add' />
                            <p className='flight_ticket_passenger_table_action_text'>Add Passenger</p>
                        </div>
                    )}
                </div>

            </div>

            <div className='flight_ticket_passenger_table_header_component'>
                <p className='flight_ticket_passenger_table_header_text'>Passenger Details</p>
            </div>
            <div>
                <CustomExpandableDataTable columns={Columns.filter((item) => item.hide !== true)} rows={datas()} no_border={true} totalServiceFee={totalServiceFee}/>
            </div>
            <div className='table_legent_main_container'>
                {/* {booking_id && (
                    <>
                        {cancellationList && cancellationList.length > 0 && (
                            <p className='passenger_table_action_summary_button' onClick={() => {
                                setCancellationSummaryModal(!cancellationSummaryModal)
                            }}>Cancellation Summary</p>
                        )}
                        {dateChangeList && dateChangeList.length > 0 && (
                            <p className='passenger_table_action_summary_button' onClick={() => {
                                setDateChangeSummaryModal(!dateChangeSummaryModal)
                            }} >Date Change Summary</p>
                        )}
                    </>
                )} */}
                {/* <GoDotFill className='table_legent_date_change_icon' />
                <p className='table_legent_date_change_text'>Date Change</p>
                <GoDotFill className='table_legent_csancel_icon' />
                <p className='table_legent_cancel_text'>Cancelled</p> */}
            </div>
        </div>
    )
}

export default TrainPassengerTable