import React, { useEffect, useState } from 'react'
import './flightTicketAdditionalInfo.css'
import { RiDownloadLine } from "react-icons/ri";
import { MdDeleteOutline } from "react-icons/md";
import CustomTextArea from '../../../../../components/custom_text_area/CustomTextArea';
import { CommonHelpers } from '../../../../../services/commonHelpers';
import { useLocation } from 'react-router-dom';
function FlightTicketAdditionalInfo({ formik, attachmnetList, setAttachmentList, attachmentDelete, client_creation_modal }) {
    const maxSizeInBytes = 2 * 1024 * 1024; // 2MB in bytes

    const location = useLocation()
    const [isView, setIsView] = useState(null)
    const [showError, setShowError] = useState({
        'attachment': ''
    });

    useEffect(() => {
        if (location.pathname.split('/').pop() === "view_flight_ticket") {
            setIsView(true)
        }
    }, [])
    function handleFileSelect(event) {
        const fileInput = event.target;
        const file = fileInput?.files[0];
        const fileName = file?.name;
        const fileType = file?.type.split("/")[1];
        // setAttachmentList({ file: file, fileName: fileName, fileType: fileType })

        if (file) { //FILE SIZE VALIDATION BY SREERAJ 7-1-25 
            if (!['jpeg', 'pdf', 'png'].includes(fileType)) {
                setShowError({ ...showError, ['attachment']: 'Unsupported file type! Please upload a jpeg, pdf, or png file.' });
                return;
            }
            if (file.size > maxSizeInBytes) {
                setShowError({ ...showError, ['attachment']: 'File size exceeds 2MB! Please upload a smaller file.' });
                return;
            }
            setAttachmentList({ file: file, fileName: fileName, fileType: fileType })
            setShowError({ ...showError, ['attachment']: '' })
        }
    }
    return (
        <div className='flight_ticket_additional_info_main_container'>
            <div>
                {!isView && (
                    <>
                        <p className='flight_ticket_additional_info_main_text'>Browse File</p>
                        <div style={{ margin: '20px 0px' }}>
                            <label htmlFor="files" className="flight_ticket_choose_file_field">
                                <span id="fileInfo">Choose file</span>
                            </label>
                            <input
                                id="files"
                                style={{ display: "none" }}
                                type="file"
                                accept="image/jpeg,image/png,application/pdf"
                                onChange={(event) => handleFileSelect(event)}
                            />
                        </div>

                    </>
                )}


                <p className='flight_ticket_additional_info_main_text'>Attachments</p>
                {showError.attachment.length > 0 && (<p className='comment_error_message'>{showError.attachment}</p>)}

                <div className='flight_ticket_additional_info_attchment_main_container'>
                    {attachmnetList && attachmnetList.length > 0 && attachmnetList.map((item, index) => (
                        <div className='flight_ticket_additional_info_attchment_item'>
                            <p className='flight_ticket_additional_info_attchment_item_text' onClick={() => {
                                if (item.url) {
                                    CommonHelpers.DownloadAssets(item.url)
                                } else {
                                    CommonHelpers.DownloadFileObjectAssets(item.fileName, item.file)
                                }
                            }} >{item.fileName ? item.fileName : item.FBPD_file}</p>
                            <div className='flight_ticket_additional_info_attchment_item_action'>
                                {!isView && (
                                    <MdDeleteOutline className='flight_ticket_additional_info_attchment_delete' onClick={() => {
                                        attachmentDelete(index, item)
                                    }} />
                                )}

                            </div>
                        </div>
                    ))}
                </div>

            </div>
            <div>
                <p>Comment</p>
                <CustomTextArea
                    rows={10}
                    isDisabled={isView}
                    name="FBD_comment"
                    placeholder={"Enter a comment"}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.FBD_comment}
                />
            </div>

        </div>
    )
}

export default FlightTicketAdditionalInfo