import { configureStore } from "@reduxjs/toolkit";
import commonReducers from "./commonStore";
import flightTicketReducers from "./process_ecr/flightTicketStore";
import packageReducers from "./process_ecr/packageStore";

export default configureStore({
  reducer: {
    commonStore: commonReducers,
    flightTicketStore: flightTicketReducers,
    packageStore: packageReducers

  },
})