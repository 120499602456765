import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik';
import * as Yup from "yup";
// import './PackagesSupplierForm.css'
import CustomAutoCompleteDropdown from '../../../../../components/custom_auto_complete_dropdown/CustomAutoCompleteDropdown';
import CustomTextField from '../../../../../components/custom_text_field/CustomTextField';
import { get_cabin_types, get_passenger_type_lists, get_titles_lists } from '../../../../../api/lookupApis';
import { get_supplier_without_pagination } from '../../../../../api/supplierApis';
import { get_gst_List } from '../../../../../api/hotelApis';
import { get_services } from '../../../../../api/servicesApis';
import { IoIosCloseCircleOutline } from "react-icons/io";
import { CgAddR } from 'react-icons/cg';
import { IconButton } from '@mui/material';
import { GrSearchAdvanced } from 'react-icons/gr';
import CustomModal from '../../../../../components/custom_modal/CustomModal';
import AdvanceSupplierFilter from '../../../master_settings/suppliers/advance_filter/AdvanceSupplierFilter';
import { SuccessToast } from '../../../../../toast/toasts';
function PackagesSupplierForm({ supplierId, supplier_creation_modal, handleSupplierCreationModal,onAmountChange, totalPassenger, passengerOtherCounts, existingPassengerDetails, onClose, setPassengerDetails, updatePassengerDetails }) {
    const [supplier, setSupplier] = useState([])
    const [passengerTitle, setPassengerTitle] = useState([])
    const [gstList, setGstList] = useState([])
    const [servicesList, setServicesList] = useState([])

    const [deletedAttachments, setDeletedAttachments] = useState([])
    const [fileAttachments, setFileAttachments] = useState([])

    useEffect(() => {
        setFileAttachments(existingPassengerDetails?.file)
    }, [existingPassengerDetails])

    const getGstList = () => {
        get_gst_List().then((response) => {
            const data = response?.map((item) => {
                return {
                    label: item.percentage,
                    value: item.gst_id,
                    persentage: item.percentage
                };
            });
            setGstList(data)
        }).catch((error) => {
            console.log(error)
        })
    }
    // const getServices = () => {
    //     get_services().then((response) => {
    //         const data = response.map((item) => {
    //             return {
    //                 label: item.ES_services_name,
    //                 value: item.ES_id,
    //             };
    //         });
    //         setServicesList(data)
    //     }).catch((error) => {
    //         console.log(error)
    //     })
    // }
    const getServices = () => {
        get_services().then((response) => {
            const data = response.map((item) => {
                return {
                    label: item.ES_services_name,
                    value: item.ES_id,
                };
            }).filter(item => item?.value === 3 || item?.value === 6);
            setServicesList(data)
        }).catch((error) => {
            console.log(error)
        })
    }

    const getSupplierList = () => {
        get_supplier_without_pagination().then((response) => {
            const data = response?.data?.data.map((item) => {
                return {
                    label: item.SD_name,
                    value: item.SD_id,
                };
            });
            setSupplier(data)
        }).catch((error) => {
            console.log(error)
        })
    }

    useEffect(() => {
        getSupplierList()
    }, [supplier_creation_modal, existingPassengerDetails])

    useEffect(() => {
        getGstList()
        getServices()
        // getPassengerTitle()
        if (existingPassengerDetails) {
            setSelectedRowId(existingPassengerDetails?.supplier_id || ""); // Select
            formik.setValues({
                ...formik.values, ...{
                    'supplier': existingPassengerDetails.supplier_name,
                    'service_id': existingPassengerDetails.service_id,
                    'amount': existingPassengerDetails.amount,
                    'gst_id': existingPassengerDetails.gst_id,
                    'percentage': existingPassengerDetails.percentage,
                    'supplier_id': existingPassengerDetails.supplier_id,
                    'file': existingPassengerDetails.file,

                }
            })
        }
    }, [existingPassengerDetails])


    const formik = useFormik({
        initialValues: {
            'amount': '',
            'gst_id': '',
            'supplier_id': '',
            'service_id': '',
            'file': []

        },
        validationSchema: Yup.object({
            service_id: Yup.string().required("Service is required"),
            amount: Yup.string().required("Amount is required"),
            gst_id: Yup.string().required("Gst is required"),
            supplier_id: Yup.string().required("Supplier is required")
        }),
        onSubmit: (values) => {

            let value = JSON.parse(JSON.stringify(values))

            value['supplier_id'] = value?.supplier_id || '';
            value['gst_id'] = value?.gst_id || '';
            value['amount'] = value?.amount
            value['service_id'] = value?.service_id || '';
            value['file'] = values?.file || [];

            // If there's existing data, updating here
            if (existingPassengerDetails) {
                value['index'] = existingPassengerDetails.index;
                updatePassengerDetails(value);
            } else {
                setPassengerDetails(value);
            }
            onClose(false)
        }
    })

    const handleReset = () => {
        formik.resetForm();
    };


    const [isAdvancedSearchOpen, setIsAdvancedSearchOpen] = useState(false);
    const [selectedRowId, setSelectedRowId] = useState(null); // Tracks the selected row

    const handleAdvancedSearchOpen = () => {
        setIsAdvancedSearchOpen(true);
        setSelectedRowId(null);
        formik.setFieldValue("supplier_id", "");

    }
    const handleAdvancedSearchClose = () => setIsAdvancedSearchOpen(false);

    // Handle select client from table
    const handleCheckboxChange = (row) => {
        // If the item is already selected,
        if (selectedRowId === row.id) {
            setSelectedRowId(null);
            formik.setFieldValue("supplier_id", "");
        } else {
            const selectedItem = supplier?.find((type) => type.value === row.id);
            setSelectedRowId(selectedItem?.value || null); // Select
            formik.setFieldValue("supplier_id", selectedItem?.value || "");
            handleAdvancedSearchClose()
        }
    };

    // Dropdown change handler
    const handleDropdownChange = (event, newValue) => {
        const selectedValue = newValue?.value || "";
        formik.setFieldValue("supplier_id", selectedValue);
        // Update selectedRowId based on the dropdown value
        setSelectedRowId(selectedValue);
    };

    // //GET NEWELY CREAETED SUPPLIER RESPONSE FROM SUPPLIER FROM
    useEffect(() => {
        if (supplierId) {
            formik.setFieldValue("supplier_id", supplierId);
        }
    }, [supplierId]);

//  function to set the supplier amount From child Component added by Evangelin
    const handleAmountUpdate = (newAmount) => {
        onAmountChange(newAmount); // Notify the parent of the new value
        formik.setFieldValue('amount', newAmount); // Optionally update formik state as well
      };
     
      
    return (
        <div className='flight_ticket_passenger_form_super_container'>
            <form id="PackagesSupplierForm" onSubmit={formik.handleSubmit}>
               
                <div className='flight_ticket_passenger_form_main_container'>
                    <p className="flight_ticket_passenger_form_legend">Supplier Details</p>
                    <div>
                        <div className='flight_ticket_passenger_form_sub_container_0'>

                            <div>
                                <p className='flight_ticket_passenger_form_label'>Service<span className='label_required_remark'>*</span></p>
                                <CustomAutoCompleteDropdown
                                    // isDisabled={booking_id && (isView && !isUpdate)}
                                    name="service_id"
                                    placeholder={"Select a Service"}
                                    options={servicesList}
                                    onChange={(event, newValue) => {
                                        formik.setFieldValue(
                                            "service_id",
                                            newValue ? newValue.value : ""
                                        );
                                    }}
                                    onBlur={formik.handleBlur}
                                    value={
                                        servicesList?.find((type) =>
                                            type.value ===
                                            formik.values.service_id
                                        ) || null
                                    }
                                    error={
                                        formik.touched.service_id &&
                                        Boolean(formik.errors.service_id)
                                    }
                                    helperText={
                                        formik.touched.service_id &&
                                        formik.errors.service_id
                                    }
                                />
                            </div>

                            <div >

                                <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                                    <p className='flight_ticket_passenger_form_label'>Supplier<span className='label_required_remark'>*</span> </p>
                                    <>
                                        <CgAddR className='packages_booking_label_action' onClick={() => {
                                            handleSupplierCreationModal(true)
                                        }} />
                                        <GrSearchAdvanced onClick={handleAdvancedSearchOpen} className="customSearchIcon" />
                                    </>
                                </div>
                                <CustomAutoCompleteDropdown
                                    // isDisabled={isView}
                                    name="supplier_id"
                                    placeholder={"Select a Supplier"}
                                    options={supplier}
                                    onChange={handleDropdownChange}

                                    onBlur={formik.handleBlur}
                                    value={
                                        supplier.find((type) =>
                                            type.value ===
                                            formik.values.supplier_id
                                        ) || null
                                    }
                                    error={
                                        formik.touched.supplier_id &&
                                        Boolean(formik.errors.supplier_id)
                                    }
                                    helperText={
                                        formik.touched.supplier_id &&
                                        formik.errors.supplier_id
                                    }
                                />
                            </div>

                            <div>
                                <p className='flight_ticket_passenger_form_label'>Amount<span className='label_required_remark'>*</span></p>
                                <CustomTextField
                                    // isDisabled={isView}
                                    name="amount"
                                    placeholder={"Enter Amount"}
                                    // onChange={formik.handleChange}
                                    onChange={(e) => handleAmountUpdate(e.target.value)} 
                                    onBlur={formik.handleBlur}
                                    value={formik.values.amount}
                                    error={formik.touched.amount &&
                                        Boolean(formik.errors.amount)
                                    }
                                    helperText={
                                        formik.touched.amount && formik.errors.amount
                                    }
                                    inputProps={{
                                        pattern: "^[0-9]*\\.?[0-9]*$",  // Allows decimals
                                        onKeyPress: (e) => {
                                            const char = e.key;
                                            const value = e.target.value;
                                            if (
                                                !/^[0-9.]$/.test(char) ||
                                                (char === "." && value.includes(".")) ||
                                                (char === "." && value === "")
                                            ) {
                                                e.preventDefault();
                                            }
                                        }
                                    }}
                                />
                            </div>

                            <div>
                                <p className='flight_ticket_passenger_form_label'>GST<span className='label_required_remark'>*</span></p>
                                <CustomAutoCompleteDropdown
                                    name="gst_id"
                                    placeholder="Select a GST"
                                    options={gstList}
                                    onChange={(event, newValue) => {
                                        formik.setFieldValue("gst_id", newValue ? newValue.value : "");
                                    }}
                                    onBlur={formik.handleBlur}
                                    value={
                                        gstList?.find((type) =>
                                            type.value === formik.values.gst_id
                                        ) || null
                                    }
                                    error={
                                        formik.touched.gst_id && Boolean(formik.errors.gst_id)
                                    }
                                    helperText={
                                        formik.touched.gst_id && formik.errors.gst_id
                                    }
                                />
                            </div>

                            {/* <PackagesSupplierAdditionalInfo formik={formik} attachmnetList={fileAttachments} setAttachmentList={set_attachment_list} attachmentDelete={delete_particular_attachment_details} /> */}

                            {/* <div>
                                <p className='flight_ticket_passenger_form_label'>gst_id</p>
                                <CustomTextField
                                    name="gst_id"
                                    placeholder={"Enter ticket number"}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.gst_id}
                                    error={formik.touched.gst_id &&
                                        Boolean(formik.errors.gst_id)
                                    }
                                    helperText={
                                        formik.touched.gst_id && formik.errors.gst_id
                                    }
                                />
                            </div> */}

                        </div>
                    </div>
                </div>
                <div className='flight_ticket_passenger_form_action_container'>
                    <div className='flight_ticket_passenger_form_single_row_alignment'>
                        {!existingPassengerDetails && (<button className='flight_ticket_passenger_form_reset_button' type='button' onClick={handleReset}>Reset</button>)}

                        <button
                            className='flight_ticket_passenger_form_save_button'
                            type="submit"
                            form="PackagesSupplierForm">{!existingPassengerDetails ? "Save" : "Update"}</button>
                    </div>
                </div>
            </form>

            <CustomModal
                maxWidth={"1500px"}
                open={isAdvancedSearchOpen}
                handleClose={handleAdvancedSearchClose}
                close={<IconButton
                    aria-label="close"
                    onClick={handleAdvancedSearchClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                > <IoIosCloseCircleOutline />
                </IconButton>}
                body={
                    <AdvanceSupplierFilter is_open_modal={true} close_modal={handleAdvancedSearchClose} selectedRowId={selectedRowId} handleCheckboxChange={handleCheckboxChange} />
                }
                title={"Advance Filter"}
            />

        </div>
    )
}

export default PackagesSupplierForm
