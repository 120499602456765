import React, { useEffect, useState } from 'react'
import './PackagesAdditionalInfo.css'
import { RiDownloadLine } from "react-icons/ri";
import { MdDeleteOutline } from "react-icons/md";
import CustomTextArea from '../../../../../components/custom_text_area/CustomTextArea';
import { CommonHelpers } from '../../../../../services/commonHelpers';
import { useLocation } from 'react-router-dom';
function PackagesAdditionalInfo({ isDisabled, attachmnetList, setAttachmentList, attachmentDelete, client_creation_modal }) {
    const maxSizeInBytes = 2 * 1024 * 1024; // 2MB in bytes

    const location = useLocation()
    const [isView, setIsView] = useState(null)
    const [showError, setShowError] = useState({
        'attachment': ''
    });

    useEffect(() => {
        if (location.pathname.split('/').pop() === "view_package") {
            setIsView(true)
        }
    }, [])
    function handleFileSelect(event) {
        const fileInput = event.target;
        const file = fileInput.files[0];
        const fileName = file?.name;
        const fileType = file?.type?.split("/")[1];
        
        if (file === undefined || fileType === undefined || fileName === undefined) {
            return
        } else {  //FILE SIZE VALIDATION BY SREERAJ 8-1-25 
            if (!['jpeg', 'pdf', 'png'].includes(fileType)) {
                setShowError({ ...showError, ['attachment']: 'Unsupported file type! Please upload a jpeg, pdf, or png file.' });
                return;
            }
            if (file.size > maxSizeInBytes) {
                setShowError({ ...showError, ['attachment']: 'File size exceeds 2MB! Please upload a smaller file.' });
                return;
            }

            // Add the new file details to the attachment list
            setAttachmentList({ file: file, fileName: fileName, fileType: fileType })
            setShowError({ ...showError, ['attachment']: '' })

        }
    }
    return (
        <div className='flight_ticket_additional_info_main_container'>
            <div>
                {!isView && (
                    <>
                        <p className='flight_ticket_additional_info_main_text'>Browse File</p>
                        <div style={{ margin: '20px 0px' }}>
                            <label htmlFor="files" className="flight_ticket_choose_file_field">
                                <span id="fileInfo">Choose file</span>
                            </label>
                            <input
                                disabled={isView}
                                id="files"
                                style={{ display: "none" }}
                                type="file"
                                accept="image/jpeg,image/png,application/pdf"
                                multiple
                                onChange={(event) => handleFileSelect(event)}
                            />
                        </div>
                    </>
                )}
                {attachmnetList && attachmnetList.length > 0 && <p className='flight_ticket_additional_info_main_text'>Attachments</p>}
                {showError.attachment.length > 0 && (<p className='comment_error_message'>{showError.attachment}</p>)}

                <div className='flight_ticket_additional_info_attchment_main_container'>
                    {attachmnetList && attachmnetList.length > 0 && attachmnetList.map((item, index) => (
                        <div className='flight_ticket_additional_info_attchment_item'>
                            <p>{item.fileName ? item.fileName : item.CGD_file_name}</p>
                            <div className='flight_ticket_additional_info_attchment_item_action'>
                                <RiDownloadLine className='flight_ticket_additional_info_attchment_download' onClick={() => {
                                    if (item.url) {
                                        CommonHelpers.DownloadAssets(item.url)
                                    } else {
                                        CommonHelpers.DownloadFileObjectAssets(item.fileName, item.file)
                                    }
                                }} />
                                {!isView && (
                                    <MdDeleteOutline className='flight_ticket_additional_info_attchment_delete' onClick={() => {
                                        attachmentDelete(index, item)
                                    }} />
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default PackagesAdditionalInfo