import React, { useState } from 'react'
import las_logo from '../../../../assets/las_logo.png'
import m2_logo from '../../../../assets/m2_logo.png'
import './loginFormStyle.css'
import { useNavigate } from 'react-router-dom'
import CustomTextField from '../../../../components/custom_text_field/CustomTextField'
import * as Yup from "yup";
import { useFormik } from 'formik'
import { user_login } from '../../../../api/authApis'
import { ErrorToast, SuccessToast } from '../../../../toast/toasts'
import { LocalStorageServices } from '../../../../services/localStorageServices'
import { get_privilages } from '../../../../api/privilageApis'
import { useDispatch } from 'react-redux'
import { updatePrivilageDetails } from '../../../../store/commonStore'
import { user_forget_password_email } from '../../../../api/userApis'
import { TailSpin } from 'react-loader-spinner'
function LoginForm() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const [forgetPassword, setForgetPassword] = useState(false)
    const login_formik = useFormik({
        initialValues: {
            "username": "",
            "password": "",
        },
        validationSchema: Yup.object({
            username: Yup.string().required("User name is required"),
            password: Yup.string().required("Password is required"),
        }),
        onSubmit: (values) => {
            const formData = new FormData();
            for (const key in values) {
                formData.append(key, values[key]);
            }
            user_login(formData).then(async (response) => {
                if (response.status == 200) {
                    LocalStorageServices.setAccessTokenLocalStorageData(response.data.access_token)
                    LocalStorageServices.setUserDetailsLocalStorageData(JSON.stringify(response.data.user))
                    navigate(`/ecrs_portal/${response.data.user.role.role_name}/dashboard`)

                    await get_privilages().then((response) => {
                        dispatch(updatePrivilageDetails(JSON.stringify(response)))
                        LocalStorageServices.setPrivilegesDetailsLocalStorageData(JSON.stringify(response))
                    }).catch((error) => {
                        console.log(error)
                    })

                }
            }).catch((error) => {
                ErrorToast(error?.data?.message)
            })
        }
    })
    const forget_password_formik = useFormik({

        initialValues: {
            "email": ""
        },
        validationSchema: Yup.object({
            email: Yup.string().email("Invalid email address").required("Email id is required"),
        }),
        onSubmit: (values, { resetForm }) => {
            setLoading(true)
            user_forget_password_email(values).then((response) => {
                if (response.code == 200) {
                    SuccessToast(response.message)
                    resetForm()
                } else {
                    ErrorToast(response.message)
                }
            }).catch((error) => {
                console.log(error)
            }).finally(() => {
                setLoading(false)
            })
        }
    })
    return (
        <div className='login_form_main_container'>
            <div className='login_form_sub_container_1'>
                <img src={las_logo} className='login_form_las_logo' />
                <img src={m2_logo} className='login_form_m2_logo' />
            </div>
            <div className='login_form_sub_container_2'>
                <h5 className='login_form_heading_title'>{forgetPassword ? 'Forgot Password?' : 'Hello Again!'}</h5>
                <p className='login_form_sub_title'>{forgetPassword ? 'Enter your registered email to reset your password and regain access.' : 'Login to grab your next oppertunity'}</p>
            </div>
            {forgetPassword ?
                <form id="forget_password_form" onSubmit={forget_password_formik.handleSubmit}>
                    <div className='login_form_fields_labels_container'>
                        <div>
                            <p className='form_label'>Email Id</p>
                            <CustomTextField
                                name="email"
                                placeholder={"Enter email"}
                                onChange={forget_password_formik.handleChange}
                                onBlur={forget_password_formik.handleBlur}
                                value={forget_password_formik.values.email}
                                error={forget_password_formik.touched.email &&
                                    Boolean(forget_password_formik.errors.email)
                                }
                                helperText={
                                    forget_password_formik.touched.email && forget_password_formik.errors.email
                                }
                            />
                        </div>
                        <div>
                            <div className='login_form_back_to_sign_in_container'>

                                <p className='login_form_forget_text' onClick={() => {
                                    setForgetPassword(false)
                                }}>Sign In</p>
                            </div>
                        </div>
                        <button disabled={loading} className='login_form_sign_up_container'
                            type="submit"
                            form="forget_password_form"> {loading ? <div className='submit_button_loader'>
                                <TailSpin
                                    visible={loading}
                                    height="20"
                                    width="20"
                                    color="white"
                                    ariaLabel="tail-spin-loading"
                                    radius="1"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                />
                            </div> : "Submit"}</button>
                    </div>

                </form>
                :
                <form id="loginform" onSubmit={login_formik.handleSubmit}>
                    <div className='login_form_fields_labels_container'>
                        <div>
                            <p className='form_label'>User Name</p>
                            <CustomTextField
                                name="username"
                                placeholder={"Enter username"}
                                onChange={login_formik.handleChange}
                                onBlur={login_formik.handleBlur}
                                value={login_formik.values.username}
                                error={login_formik.touched.username &&
                                    Boolean(login_formik.errors.username)
                                }
                                helperText={
                                    login_formik.touched.username && login_formik.errors.username
                                }
                            />
                        </div>
                        <div>
                            <p className='form_label'>Password</p>
                            <CustomTextField
                                name="password"
                                type="password"
                                placeholder={"Enter password"}
                                onChange={login_formik.handleChange}
                                onBlur={login_formik.handleBlur}
                                value={login_formik.values.password}
                                error={login_formik.touched.password &&
                                    Boolean(login_formik.errors.password)
                                }
                                helperText={
                                    login_formik.touched.password && login_formik.errors.password
                                }
                            />

                        </div>
                        <div className='login_form_remember_forget_container'>
                            <div className='login_form_remember_me_container'>
                                <input placeholder='Password' type="checkbox" />
                                <p className='login_form_remember_me_text'>Remember me</p>
                            </div>
                            <div>
                                <p className='login_form_forget_text' onClick={() => {
                                    setForgetPassword(true)
                                }}>Forget password ?</p>
                            </div>
                        </div>
                        <button className='login_form_sign_up_container'
                            type="submit"
                            form="loginform"> Sign In </button>
                    </div>
                </form>}


        </div>
    )
}

export default LoginForm