import React, { useState } from 'react'
import { hotel_booking_level_actions } from '../../../../../api/process_ecr/hotelBookingApis'
import CustomTextField from '../../../../../components/custom_text_field/CustomTextField'
import CustomTextArea from '../../../../../components/custom_text_area/CustomTextArea'
import { CommonHelpers } from '../../../../../services/commonHelpers'
import { ErrorToast, SuccessToast } from '../../../../../toast/toasts'
import CustomModal from '../../../../../components/custom_modal/CustomModal'
import { MdDeleteOutline } from "react-icons/md";
function HotelFinanceLevelModal({ selected_item, open_modal, modal_close }) {
    const maxSizeInBytes = 2 * 1024 * 1024; // 2MB in bytes

    const [attachments, setAttachments] = useState([])
    const [fieldValues, setfieldValues] = useState({
        'invoice_number': '',
        'comment': ''
    })
    const [showError, setShowError] = useState({
        'invoice_number': '',
        'comment': '',
        'attachment': ''
    })
    const onSubmit = () => {
        let FieldValidation = []
        let errors = {
            'invoice_number': '',
            'comment': '',
            'attachment': ''
        }
        if (!fieldValues.comment.trim()) {
            errors.comment = "Comment is required"
        }
        if ((selected_item?.type === "Verify" || selected_item?.type === "CancelVerified") && !fieldValues.invoice_number.trim()) {
            errors.invoice_number = "Invoice number is required"
        }
        if ((selected_item?.type === "Verify" || selected_item?.type === "CancelVerified") && attachments.length === 0) {
            errors.attachment = "Attachment is required"
        }

        if (errors.comment.trim()) {
            setShowError(errors)
        }
        if (showError.attachment !== "") { //FOR ATTACHMENT HAVE INVALID FILE 
            return
        }
        if ((selected_item?.type === "Verify" || selected_item?.type === "CancelVerified") && FieldValidation.length > 0) return
        if (!errors.comment.trim() && !((selected_item?.type === "Verify" || selected_item?.type === "CancelVerified") && errors.invoice_number.trim()) && !((selected_item?.type === "Verify" || selected_item?.type === "CancelVerified") && errors.attachment.trim())) {
            const formData = new FormData();
            formData.append('booking_id', selected_item?.id)
            if (selected_item?.type !== "Hold") {
                attachments.map((item, index) => {
                    formData.append(`attachment`, item.file);
                })
                formData.append('invoice_number', fieldValues.invoice_number)
            }
            formData.append('type', selected_item?.type)
            formData.append('comments', fieldValues.comment)
            hotel_booking_level_actions(formData).then((response) => {
                if (response?.data?.status === true) {
                    SuccessToast(response?.data?.message)
                    modal_close(false)
                } else { //added by sajin 9/12/24 
                    ErrorToast(response?.data?.message || `Something went wrong!`)
                }
            }).catch((error) => {
                console.log(error)
            }).finally(() => {
                // setLoading(false)
            })

        } else {
            setShowError(errors)
        }


    }
    function handleFileSelect(event, index) {
        const fileInput = event.target;
        const file = fileInput?.files[0];
        const fileName = file?.name;
        const fileType = file?.type.split("/")[1];
        if (file) {
            if (!['jpeg', 'pdf', 'png'].includes(fileType)) {
                setShowError({ ...showError, ['attachment']: 'Unsupported file type! Please upload a jpeg, pdf, or png file.' });
                return;
            }
            if (file.size > maxSizeInBytes) {
                setShowError({ ...showError, ['attachment']: 'File size exceeds 2MB! Please upload a smaller file.' });
                return;
            }
            setAttachments([...attachments, { file: file, fileName: fileName, fileType: fileType }])
            if (showError.attachment.length > 0) {
                setShowError({ ...showError, ['attachment']: '' })
            }
        }
    }

    const delete_particular_attachment = (index, item) => {
        attachments.splice(index, 1)
        setAttachments([...attachments])
    }
    const resetStates = () => {
        setAttachments([])
        setfieldValues({
            'invoice_number': '',
            'comment': ''
        })
        setShowError({
            'invoice_number': '',
            'comment': '',
            'attachment': ''
        })
    }
    return (
        <div>
            <CustomModal
                maxWidth={'500px'}
                open={open_modal}
                handleClose={(e) => {
                    modal_close(e)
                    resetStates()
                }}
                body={
                    <div>
                        {selected_item?.type !== "Hold" && (
                            <>
                                <div>
                                    <p className='finance_level_modal_label'>Invoice Number<span className='label_required_remark'>*</span></p>
                                    <CustomTextField
                                        autoFocus
                                        placeholder={"Enter a invoice number"}
                                        onChange={(e) => {
                                            setfieldValues({ ...fieldValues, 'invoice_number': e.target.value })
                                            if (showError.invoice_number.length > 0) {
                                                setShowError({ ...showError, ['invoice_number']: '' })
                                            }
                                        }}
                                    />
                                    {((selected_item?.type === "Verify" || selected_item?.type === "CancelVerified") && showError.invoice_number.length > 0) && (<p className='comment_error_message'>{showError.invoice_number}</p>)}
                                </div>

                            </>
                        )}
                        <div>
                            <p className='finance_level_modal_label'>Comment<span className='label_required_remark'>*</span></p>
                            <CustomTextArea
                                autoFocus={selected_item?.type === "Hold"}
                                rows={5}
                                placeholder={"Enter a comment"}
                                onChange={(e) => {
                                    setfieldValues({ ...fieldValues, 'comment': e.target.value })
                                    if (showError.comment.length > 0) {
                                        setShowError({ ...showError, ['comment']: '' })
                                    }

                                }}
                            />
                            {showError.comment.length > 0 && (<p className='comment_error_message'>{showError.comment}</p>)}
                        </div>
                        {selected_item?.type !== "Hold" && (
                            <div>
                                <p className='finance_level_modal_label'>Attachment<span className='label_required_remark'>*</span></p>
                                <div style={{ margin: '20px 0px' }}>
                                    <label htmlFor="files" className="flight_ticket_choose_file_field">
                                        <span id="fileInfo">Choose file</span>
                                    </label>
                                    <input
                                        id="files"
                                        style={{ display: "none" }}
                                        type="file"
                                        accept="image/jpeg,image/png,application/pdf"
                                        onChange={(event) => handleFileSelect(event)}
                                    />
                                </div>
                                {showError.attachment.length > 0 && (<p className='comment_error_message'>{showError.attachment}</p>)}
                                <div>
                                    {attachments && attachments.length > 0 && attachments.map((item, index) => (
                                        <div key={index} className='flight_ticket_additional_info_attchment_item'>
                                            <p className='flight_ticket_additional_info_attachment_item' onClick={() => {
                                                if (item.url) {
                                                    CommonHelpers.DownloadAssets(item.url)
                                                } else {
                                                    CommonHelpers.DownloadFileObjectAssets(item.fileName, item.file)
                                                }
                                            }} >{item.fileName ? item.fileName : item.FBPD_file}</p>
                                            <div>
                                                <MdDeleteOutline className='flight_ticket_additional_info_attachment_item_delete' onClick={() => {
                                                    delete_particular_attachment(index, item)
                                                }} />

                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}

                        <div className='finance_level_modal_form_single_row_alignment'>
                            <button className='finance_level_modal_form_reset_button' onClick={() => {
                                modal_close(false)
                                resetStates()
                            }}>Cancel</button>

                            <button className='finance_level_modal_form_update_button'
                                type="submit"
                                onClick={() => {
                                    onSubmit()
                                }}>Submit</button>
                        </div>
                    </div>
                }
                title_other={'Enquiry - ' + selected_item?.enquiry_number}
                title={selected_item?.type === "Hold" ? "Hold Booking" : "Verify Booking"}
            />
        </div>
    )
}

export default HotelFinanceLevelModal