
import { hotel_booking_level_actions } from '../../../../../api/process_ecr/hotelBookingApis'
import React, { useEffect, useState } from 'react'
import CustomModal from '../../../../../components/custom_modal/CustomModal'
import CustomTextArea from '../../../../../components/custom_text_area/CustomTextArea'
import CustomTextField from '../../../../../components/custom_text_field/CustomTextField'
import { flight_ticket_cancellation_reasons } from '../../../../../api/process_ecr/flightTicketApis'
import { ErrorToast, SuccessToast } from '../../../../../toast/toasts'
import CustomAutoCompleteDropdown from '../../../../../components/custom_auto_complete_dropdown/CustomAutoCompleteDropdown'
import * as Yup from 'yup';
import { useFormik } from 'formik'
import './HotelCancelModal.css'

function HotelCancelModal({ selected_item, open_modal, modal_close }) {
    const [cancelTicketReasons, setCancelTicketReasons] = useState([])
    const [refundAmount, setRefundAmount] = useState(0)
    const [validRefundAmount, setValidRefundAmount] = useState(0)

    const get_passenger_cancel_reasons = () => {
        flight_ticket_cancellation_reasons().then((response) => {
            const reStructureResponse = response.data.map((item) => { return { 'value': item.CRL_id, 'label': item.CRL_reason } })
            setCancelTicketReasons(reStructureResponse)
        }).catch((error) => {
            console.log(error)
        })
    }

    useEffect(() => {
        if (open_modal === true) {
            get_passenger_cancel_reasons()
            resetFields()
        }
    }, [open_modal])

    //NEW--------------
    // Validation schema using Yup
    const validationSchema = Yup.object({
        service_fee: Yup.number()
            .min(0, "Service fee must be at least 0")
            .required("Service fee is required"),
        supplier_amount: Yup.number()
            .min(0, "Supplier amount must be at least 0")
            .required("Supplier amount is required"),
        cancel_reason: Yup.string()
            .required("Cancel Reason is required"),
        comments: Yup.string()
            .required("Comment is required"),
    });

    const formik = useFormik({
        initialValues: {
            service_fee: 0,//'',
            supplier_amount: 0,//'',
            cancel_reason: '',
            comments: '',

        },
        validationSchema,
        onSubmit: (values) => {
            const type = `CancelInitiated`
            const payload = { ...values, booking_id: selected_item?.id, refund_amount: refundAmount, type }
            hotel_booking_level_actions(payload).then((response) => {
                if (response.data.status === true) {
                    SuccessToast(response.data.message)
                    modal_close(false);
                    resetFields()

                } else {
                    ErrorToast(response?.data?.message)
                }
            }).catch((error) => {
                console.log(error)
                ErrorToast(`Something went wrong!`)

            })

        },
    });

    // State to temporarily hide validation messages while typing
    const [hideValidation, setHideValidation] = useState({
        service_fee: false,
        supplier_amount: false,
        cancel_reason: false,
        comments: false,
    });

    function resetFields() {
        // Reset Formik values and touched states
        formik.resetForm({
            values: {
                service_fee: 0,//'',
                supplier_amount: 0,//'',
                cancel_reason: '',
                comments: '',
            },
        });
    }


    const NumberInputDisplay = ({ serviceFee = 0, supplierAmount = 0 }) => {
        const totalRefundableAmount = (selected_item?.clientAmount) - (serviceFee + supplierAmount)
        setRefundAmount(totalRefundableAmount)

        const validAmount = totalRefundableAmount >= 0 //FOR VALID REFUND AMOUNT
        setValidRefundAmount(validAmount);
    };

    return (
        <>
            <CustomModal
                maxWidth={"500px"}
                open={open_modal}
                handleClose={(e) => {
                    modal_close(false);
                    resetFields();
                }}
                body={
                    <form onSubmit={formik.handleSubmit}>
                        {!validRefundAmount && <span className='invalid_refund_warning'>Refundable amount cannot be negative</span>}

                        <div className='cancel_main_container'>
                            {/* Service Fee Input */}
                            <div>
                                <p className='finance_level_modal_label'>Service Fee <span className='label_required_remark'>*</span></p>
                                <CustomTextField
                                    type="number"
                                    name="service_fee"
                                    placeholder="Enter client amount"
                                    onChange={(e) => {
                                        formik.handleChange(e);
                                        if (e.target.value !== '') {
                                            setHideValidation((prev) => ({ ...prev, service_fee: true }));
                                            const amount = Number(e.target.value);
                                            formik.setFieldValue("service_fee", amount);
                                        }
                                    }}
                                    onBlur={(e) => {
                                        formik.handleBlur(e);
                                        setHideValidation((prev) => ({ ...prev, service_fee: false }));
                                    }}
                                    inputProps={{ inputProps: { min: 0, step: "0.01" } }}
                                    value={formik.values.service_fee}
                                    error={
                                        !hideValidation.service_fee &&
                                        formik.touched.service_fee &&
                                        Boolean(formik.errors.service_fee)
                                    }
                                    helperText={
                                        !hideValidation.service_fee &&
                                        formik.touched.service_fee &&
                                        formik.errors.service_fee
                                    }

                                />
                                <NumberInputDisplay serviceFee={formik?.values?.service_fee || 0} supplierAmount={formik?.values?.supplier_amount || 0} />
                            </div>
                            <div>
                                {/* Supplier Amount Input */}
                                <p className='finance_level_modal_label'>Supplier Amount <span className='label_required_remark'>*</span></p>
                                <CustomTextField
                                    type="number"
                                    name="supplier_amount"
                                    placeholder="Enter supplier amount"
                                    onChange={(e) => {
                                        formik.handleChange(e);
                                        if (e.target.value !== '') {
                                            setHideValidation((prev) => ({ ...prev, supplier_amount: true }));
                                            const amount = Number(e.target.value);
                                            formik.setFieldValue("supplier_amount", amount);
                                        }
                                    }}
                                    onBlur={(e) => {
                                        formik.handleBlur(e);
                                        setHideValidation((prev) => ({ ...prev, supplier_amount: false }));
                                    }}
                                    inputProps={{ inputProps: { min: 0, step: "0.01" } }}
                                    value={formik.values.supplier_amount}
                                    error={
                                        !hideValidation.supplier_amount &&
                                        formik.touched.supplier_amount &&
                                        Boolean(formik.errors.supplier_amount)
                                    }
                                    helperText={
                                        !hideValidation.supplier_amount &&
                                        formik.touched.supplier_amount &&
                                        formik.errors.supplier_amount
                                    }
                                />
                                <NumberInputDisplay serviceFee={formik?.values?.service_fee || 0} supplierAmount={formik?.values?.supplier_amount || 0} />

                            </div>

                        </div>



                        {/* Cancel Reason Dropdown */}
                        <p className='finance_level_modal_label'>Cancel Reason <span className='flight_cancel_form_label_required'>*</span></p>
                        <CustomAutoCompleteDropdown
                            placeholder="Select reason"
                            options={cancelTicketReasons}
                            onChange={(event, newValue) => {
                                formik.setFieldValue(
                                    "cancel_reason",
                                    newValue ? newValue.value : ""
                                );
                            }}
                            value={
                                cancelTicketReasons.find((type) =>
                                    type.value ===
                                    formik.values.cancel_reason
                                ) || null
                            }

                            onBlur={(e) => {
                                formik.handleBlur(e);
                            }}
                            error={
                                !hideValidation.cancel_reason &&
                                formik.touched.cancel_reason &&
                                Boolean(formik.errors.cancel_reason)
                            }
                            helperText={
                                !hideValidation.cancel_reason &&
                                formik.touched.cancel_reason &&
                                formik.errors.cancel_reason
                            }
                        />

                        {/* Comment Input */}
                        <p className='finance_level_modal_label'>Comment <span className='label_required_remark'>*</span></p>
                        <CustomTextArea
                            rows={5}
                            placeholder="Enter a comment"
                            name="comments"
                            value={formik.values.comments}
                            onChange={(e) => {
                                formik.handleChange(e);
                                setHideValidation((prev) => ({ ...prev, comments: true }));
                            }}
                            onBlur={(e) => {
                                formik.handleBlur(e);
                                setHideValidation((prev) => ({ ...prev, comments: false }));
                            }}
                            error={
                                !hideValidation.comments &&
                                formik.touched.comments &&
                                Boolean(formik.errors.comments)
                            }
                            helperText={
                                !hideValidation.comments &&
                                formik.touched.comments &&
                                formik.errors.comments
                            }
                        />

                        <div style={{ marginTop: '1rem' }}>

                            <div className="hotel_cancel_form_row_alignment">
                                <p className="hotel_cancel_form_label_total_text">Customer Net</p>
                                <p className="hotel_cancel_form_label_total_text">{Number(selected_item?.clientAmount)}</p>
                            </div>

                            <div className="hotel_cancel_form_row_alignment">
                                <p className="hotel_cancel_form_label_total_text">Refund Amount</p>
                                <p className="hotel_cancel_form_label_total_text">{refundAmount}</p>
                            </div>

                        </div>


                        {/* Action Buttons */}
                        {validRefundAmount && <div className='finance_level_modal_form_single_row_alignment'>

                            <button
                                className='finance_level_modal_form_reset_button'
                                onClick={() => {
                                    modal_close(false);
                                    resetFields();
                                }}
                            >
                                Cancel
                            </button>
                            <button
                                className='finance_level_modal_form_update_button'
                                type="submit"
                            // onClick={onSubmit}
                            >
                                Submit
                            </button>
                        </div>}
                    </form>
                }
                title={"Cancel Booking"}
            />
        </>
    )
}

export default HotelCancelModal