import React, { useEffect, useState } from 'react'
import './generalSettingsStyle.css'
import EmailNotification from './sub_components/email_notification/EmailNotification'
import TaxSettings from './sub_components/tax_settings/TaxSettings';
import { useNavigate } from 'react-router-dom';
import { LocalStorageServices } from '../../../../services/localStorageServices';
import Airlines from './sub_components/airlines/Airlines';
import { hasPrivilege } from '../../../../common_privilige_methods/Privilege_Methods';

function GeneralSettings() {
    let baseRouteUrl = `/ecrs_portal/${LocalStorageServices.getUserRole()}/`
    const navigate = useNavigate()
    const [selectedTab, setSelectedTab] = useState(0)
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get('selected');
    const tabs = [{
        key: 0,
        name: "Email notification settings"
    },
    {
        key: 1,
        name: "Tax settings"
    },
    // {
    //     key: 2,
    //     name: "Airline settings"
    // }
    ]

    useEffect(() => {
        if (!myParam) {
            navigate(baseRouteUrl + 'master_settings/general_settings?selected=0')
            setSelectedTab(0)
        } else {
            setSelectedTab(myParam)
        }

    }, [myParam])


    const isPrivileges_Add = hasPrivilege("Master Settings", "General Settings", "Add");

    return (
        <div className='general_settings_module_main_container'>
            <div className='general_settings_tabs_main_container'>
                {tabs && tabs.map((item) => (
                    <p className={item.key == selectedTab ? 'selected_general_settings_tabs' : 'un_selected_general_settings_tabs'} onClick={() => {
                        if (item.key == 0) {
                            navigate(baseRouteUrl + 'master_settings/general_settings?selected=0')
                        } else if (item.key == 1) {
                            navigate(baseRouteUrl + 'master_settings/general_settings?selected=1')
                        }
                        //  else if (item.key == 2) {
                        //     navigate(baseRouteUrl + 'master_settings/general_settings?selected=2')
                        // }
                    }} >{item.name}</p>
                ))}
            </div>
            <div>
                {selectedTab == 0 ? <EmailNotification isSavePermission={isPrivileges_Add} />
                    : selectedTab == 1 ? <TaxSettings isSavePermission={isPrivileges_Add} /> : <></>
                    // : selectedTab == 2 ? <Airlines isSavePermission={isPrivileges_Add} /> : <></>
                }

            </div>
        </div>
    )
}

export default GeneralSettings