import { createSlice } from "@reduxjs/toolkit";

const flightTicketReducer = createSlice({
    name: 'flight_ticket',
    initialState: {
        defaultColumnChecked: [0, 1, 3, 4, 5, 7, 10, 11, 12],
        ecr_filter: {
            "FBD_PNR": '',
            "FBD_ticket_type_id": '',
            "FBD_trip_type": '',
            "FBD_supplier_id": '',
            "FBD_client_id": '',
            "FBD_issue_start_date": '',
            "FBD_issue_end_date": '',
            "FBD_ECRstatusID": '',
        }
    },
    reducers: {
        updateColumCheckList: (state, action) => {
            const checkedIndex = action.payload
            if (state.defaultColumnChecked.includes(checkedIndex)) {
                state.defaultColumnChecked = state.defaultColumnChecked.filter((item) => item != checkedIndex)
            } else {
                state.defaultColumnChecked = [...state.defaultColumnChecked, checkedIndex]
            }
        },

        updateSearchFilter: (state, action) => {
            state.ecr_filter = action.payload
        },
        resetSearchFilter: (state, action) => {
            state.ecr_filter = {
                "FBD_PNR": '',
                "FBD_ticket_type_id": '',
                "FBD_trip_type": '',
                "FBD_supplier_id": '',
                "FBD_client_id": '',
                "FBD_issue_start_date": '',
                "FBD_issue_end_date": '',
                "FBD_ECRstatusID": '',
            }
        }
    }
})
export const { updateColumCheckList, updateSearchFilter, resetSearchFilter } = flightTicketReducer.actions

export default flightTicketReducer.reducer