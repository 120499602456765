import React, { useEffect, useState } from 'react'
import './dashboardStyle.css'
import CustomCardV1 from '../../../components/custom_card_v1/CustomCardV1'
import flight_icon from '../../../assets/flight_icon.png'
import packages_icon from '../../../assets/packages_icon.png'
import cabs_icon from '../../../assets/cabs_icon.png'
import visa_icon from '../../../assets/visa_icon.png'
import hotel_resort_icon from '../../../assets/hotel_resort_icon.png'
import passport_icon from '../../../assets/passport_icon.png'
import insurance_icon from '../../../assets/insurance_icon.png'
import train_icon from '../../../assets/train_icon.png'
import CustomHorizontalDivider from '../../../components/custom_horizontal_divider/CustomHorizontalDivider'
import { get_dashboard_datas } from '../../../api/dashboardApis'
import DashboardFilter from './dashboard_filter/DashboardFilter'
import { useFormik } from 'formik';
import dayjs from 'dayjs'
import DashboardStatus from './dashboard_with_status/DashboardStatus'
import { LocalStorageServices } from '../../../services/localStorageServices'
import PendingItemCard from './dashboard_with_status/dashboard_with_status_list/status_card/PendingItemCard'
import StatusDisplay from './dashboard_with_status/dashboard_with_status_list/status_card/StatusCard'
import { useNavigate } from 'react-router-dom'

function Dashboard() {
    let baseRouteUrl = `/ecrs_portal/${LocalStorageServices.getUserRole()}/`
    const navigate=useNavigate()
    const [dashboardData, setDashboardData] = useState(null)
    const [period, setPeriod] = useState('today'); // Default period
    const [year, setYear] = useState(new Date().getFullYear()); // Default current year
    const [years, setYears] = useState([]); // All  years

    const formik = useFormik({
        initialValues: {
            start_date: null,
            end_date: null,
        },
    });

    useEffect(() => {
        const params = {
            period,
            year,
        };
        if (period === `range`) {

            if (formik.values.start_date && formik.values.end_date) {
                const rangeParams = {
                    start_date: formik.values.start_date,
                    end_date: formik.values.end_date,
                    period
                };

                // Make API call for range
                get_dashboard_datas(rangeParams)
                    .then((response) => {
                        setDashboardData(response?.data?.data);
                        const formattedYearOptions = response?.data?.data?.years?.map((year) => ({
                            value: year,
                            label: year.toString(),
                        }));
                        setYears(formattedYearOptions);
                    })
                    .catch((error) => {
                        console.error("Error fetching dashboard data:", error);
                    });
            } else {
                console.log("Start date or end date is missing.");
            }
        } else {
            get_dashboard_datas(params).then((response) => {
                setDashboardData(response?.data?.data)
                const formattedYearOptions = response?.data?.data?.years?.map((year) => ({
                    value: year,
                    label: year.toString(),
                }));

                setYears(formattedYearOptions)
            }).catch((error) => {
                console.log(error)
            })
        }


    }, [period, year, formik.values.start_date, formik.values.end_date])

    const resetFilter = () => {
        formik.resetForm()
        setPeriod(`today`)
        setYear(new Date().getFullYear());
    }

    const user_details = LocalStorageServices.getUserDetailsLocalStorageData()
    let ParsedUserDetails = user_details ? JSON.parse(user_details) : user_details
    //CHECK THE LOGGED USER IS CFO OR SUPER ADMIN OR COO
    const isCfoOrCoo = ParsedUserDetails?.role?.role_id === 8 || ParsedUserDetails?.role?.role_id === 31 //31 means COO ROLE
    const isSuperAdminOrAdmin = ParsedUserDetails?.role?.role_id === 2 || ParsedUserDetails?.role?.role_id === 1 // 1=== super admin & 2 ===admin
    const navigate_to_module = (module) => {
        if (module === "Package") {
            navigate(baseRouteUrl + `process_ecr/packages`)
        } else if (module === "Flight") {
            navigate(baseRouteUrl + `process_ecr/flight_ticket`)
        } else if (module === "Hotel") {
            navigate(baseRouteUrl + `process_ecr/hotels`)
        } else if (module === "Cabs") {
            navigate(baseRouteUrl + `process_ecr/cabs`)
        } else if (module === "Passport") {
            navigate(baseRouteUrl + `process_ecr/passport`)
        } else if (module === "Visa") {
            navigate(baseRouteUrl + `process_ecr/visa`)
        } else if (module === "Insurance") {
            navigate(baseRouteUrl + `process_ecr/travel_insurance`)
        } else if (module === "Train") {
            navigate(baseRouteUrl + `process_ecr/train`)
        }
    }
    return (
        <div className="scrollable-container">
            <div className='dashboard_main_container_0'>
                <div className='dashboard_sub_container'>
                    <p className='dashboard_title_text_1'>Dashboard</p>

                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', marginBottom: 30 }}>
               
                    {isCfoOrCoo ? (
                        <PendingItemCard label={`Pending for Approval`} count={dashboardData?.statusCount?.verified} status={`Verified`} isBtnShow={true} />
                    ) : (isSuperAdminOrAdmin && <StatusDisplay statusCount={dashboardData?.statusCount} />)}

                    <DashboardFilter
                        period={period}
                        setPeriod={setPeriod}
                        
                        year={year}
                        setYear={setYear}
                        yearOptions={years}
                        formik={formik}
                        resetFilter={resetFilter}
                    />
                </div>

                <div className='dashboard_card_listing_main_container'>
                    <CustomCardV1 miniContainerColor={"#F3ABAB"} borderColor={"#F8A9A9"} backgroundColor={"#fffbfb"} image={flight_icon} text={'Flights'} onClick={()=>{
                        navigate_to_module("Flight")
                    }}>
                        <div>
                            <div className='dashboard_row_alignment'>
                                <p className='dashboard_main_text'>Domestic Tickets</p>
                                <p className='dashboard_main_text'>{dashboardData?.flightIDomesticCount}</p>
                            </div>
                            <div className='dashboard_row_alignment'>
                                <p className='dashboard_sub_text'>Sales Value</p>
                                <p className='dashboard_sub_text'>{dashboardData?.flightDomesticSales}</p>
                            </div>
                            <CustomHorizontalDivider height={10} />
                            <div className='dashboard_row_alignment'>
                                <p className='dashboard_main_text'>International Tickets</p>
                                <p className='dashboard_main_text'>{dashboardData?.flightInternationalCount}</p>
                            </div>
                            <div className='dashboard_row_alignment'>
                                <p className='dashboard_sub_text'>Sales Value</p>
                                <p className='dashboard_sub_text'>{dashboardData?.flightInternationalSales}</p>
                            </div>
                            {/* <CustomHorizontalDivider height={10}/>
                        <div className='dashboard_row_alignment'>
                            <p className='dashboard_main_text'>Total Tickets</p>
                            <p className='dashboard_main_text'>{dashboardData?.flightTotalCount}</p>
                        </div> */}
                        </div>
                    </CustomCardV1>
                    <CustomCardV1 miniContainerColor={"#AA71A4"} borderColor={"#967492"} backgroundColor={"#f8f1f7"} image={packages_icon} text={'Packages'} onClick={()=>{
                        navigate_to_module("Package")
                    }}>
                        <div>
                            <div className='dashboard_row_alignment'>
                                <p className='dashboard_main_text'>Domestic Packages</p>
                                <p className='dashboard_main_text'>{dashboardData?.packageDomesticCount}</p>
                            </div>
                            <div className='dashboard_row_alignment'>
                                <p className='dashboard_sub_text'>Sales Value</p>
                                <p className='dashboard_sub_text'>{dashboardData?.packageDomesticSales}</p>
                            </div>
                            <CustomHorizontalDivider height={10} />
                            <div className='dashboard_row_alignment'>
                                <p className='dashboard_main_text'>International Packages</p>
                                <p className='dashboard_main_text'>{dashboardData?.packageInternationalCount}</p>
                            </div>
                            <div className='dashboard_row_alignment'>
                                <p className='dashboard_sub_text'>Sales Value</p>
                                <p className='dashboard_sub_text'>{dashboardData?.packageInternationalSales}</p>
                            </div>
                        </div>
                    </CustomCardV1>
                    <CustomCardV1 miniContainerColor={"#8481E1"} borderColor={"#8481E1"} backgroundColor={"#fafaff"} image={cabs_icon} text={'Cabs'} onClick={()=>{
                        navigate_to_module("Cabs")
                    }}>
                        <div>
                            <div className='dashboard_row_alignment'>
                                <p className='dashboard_main_text'>LAS - Travels Cabs</p>
                                <p className='dashboard_main_text'>{dashboardData?.internalCabCount}</p>
                            </div>
                            <div className='dashboard_row_alignment'>
                                <p className='dashboard_sub_text'>Sales Value</p>
                                <p className='dashboard_sub_text'>{dashboardData?.internalCabSales}</p>
                            </div>
                            <CustomHorizontalDivider height={10} />
                            <div className='dashboard_row_alignment'>
                                <p className='dashboard_main_text'>Other Cabs</p>
                                <p className='dashboard_main_text'>{dashboardData?.externalCabCount}</p>
                            </div>
                            <div className='dashboard_row_alignment'>
                                <p className='dashboard_sub_text'>Sales Value</p>
                                <p className='dashboard_sub_text'>{dashboardData?.externalCabSales}</p>
                            </div>
                        </div>
                    </CustomCardV1>
                    <CustomCardV1 miniContainerColor={"#669664"} borderColor={"#669664"} backgroundColor={"#f5fff4"} image={visa_icon} text={'Visa/Attestation'} onClick={()=>{
                        navigate_to_module("Visa")
                    }}>
                        <div>
                            <div className='dashboard_row_alignment'>
                                <p className='dashboard_main_text'>Total Visa</p>
                                <p className='dashboard_main_text'>{dashboardData?.visaCount}</p>
                            </div>
                            <div className='dashboard_row_alignment'>
                                <p className='dashboard_sub_text'>Sales Value</p>
                                <p className='dashboard_sub_text'>{dashboardData?.visaSales}</p>
                            </div>
                            <CustomHorizontalDivider height={10} />
                            <div className='dashboard_row_alignment'>
                                <p className='dashboard_main_text'>Total Attestation</p>
                                <p className='dashboard_main_text'>{dashboardData?.attestationCount}</p>
                            </div>
                            <div className='dashboard_row_alignment'>
                                <p className='dashboard_sub_text'>Sales Value</p>
                                <p className='dashboard_sub_text'>{dashboardData?.attestationSales}</p>
                            </div>
                        </div>
                    </CustomCardV1>
                    <CustomCardV1 miniContainerColor={"#388181"} borderColor={"#388181"} backgroundColor={"#eefcfc"} image={hotel_resort_icon} text={'Hotel/Resort'} onClick={()=>{
                        navigate_to_module("Hotel")
                    }}>
                        <div>
                            <div className='dashboard_row_alignment'>
                                <p className='dashboard_main_text'>Totel Bookings</p>
                                <p className='dashboard_main_text'>{dashboardData?.hotelCount}</p>
                            </div>
                            <div className='dashboard_row_alignment'>
                                <p className='dashboard_sub_text'>Sales Value</p>
                                <p className='dashboard_sub_text'>{dashboardData?.hotelSales}</p>
                            </div>

                        </div>
                    </CustomCardV1>
                    <CustomCardV1 miniContainerColor={"#C2194F"} borderColor={"#C2194F"} backgroundColor={"#fff9fb"} image={passport_icon} text={'Passport'} onClick={()=>{
                        navigate_to_module("Passport")
                    }}>
                        <div>
                            <div className='dashboard_row_alignment'>
                                <p className='dashboard_main_text'>Total Passport</p>
                                <p className='dashboard_main_text'>{dashboardData?.passportCount}</p>
                            </div>
                            <div className='dashboard_row_alignment'>
                                <p className='dashboard_sub_text'>Sales Value</p>
                                <p className='dashboard_sub_text'>{dashboardData?.PassportSales}</p>
                            </div>
                        </div>
                    </CustomCardV1>
                    <CustomCardV1 miniContainerColor={"#92543D"} borderColor={"#92543D"} backgroundColor={"#fff8f5"} image={insurance_icon} text={'Insurance'} onClick={()=>{
                        navigate_to_module("Insurance")
                    }}>
                        <div>
                            <div className='dashboard_row_alignment'>
                                <p className='dashboard_main_text'>Total Insurance</p>
                                <p className='dashboard_main_text'>{dashboardData?.insuranceCount}</p>
                            </div>
                            <div className='dashboard_row_alignment'>
                                <p className='dashboard_sub_text'>Sales Value</p>
                                <p className='dashboard_sub_text'>{dashboardData?.insuranceSales}</p>
                            </div>
                        </div>
                    </CustomCardV1>
                    <CustomCardV1 miniContainerColor={"#DC14B1"} borderColor={"#D481E1"} backgroundColor={"#FFE1FA"} image={train_icon} text={'Train'}  onClick={()=>{
                        navigate_to_module("Train")
                    }}>
                        <div>
                            <div className='dashboard_row_alignment'>
                                <p className='dashboard_main_text'>Total Train</p>
                                <p className='dashboard_main_text'>{dashboardData?.trainCount}</p>
                            </div>
                            <div className='dashboard_row_alignment'>
                                <p className='dashboard_sub_text'>Sales Value</p>
                                <p className='dashboard_sub_text'>{dashboardData?.trainSales}</p>
                            </div>
                        </div>
                    </CustomCardV1>
                </div>
            </div>
            {/* <div>
                <DashboardStatus statusCount={dashboardData?.statusCount} />
            </div> */}
        </div>
    )
}

export default Dashboard