import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    package_filter: {
        'filename': '',
        "hotel": '',
        "date": "",
        'designation': '',
        'client': "",
        'start_date': '',
        'end_date': '',
        'status': '',
        'ticket_type': ''
    },
};


const packageSlice = createSlice({
    name: 'packageStore',
    initialState,
    reducers: {
        updateSearchFilter: (state, action) => {
            state.package_filter = {
                ...state.package_filter,
                ...action.payload, // Merge new updates with the existing state
            };
        },
        resetSearchFilter: (state, action) => {
            state.package_filter = {
                'filename': '',
                "hotel": '',
                "date": "",
                'designation': '',
                'client': "",
                'start_date': '',
                'end_date': '',
                'status': '',
                'ticket_type': ''
            }
        }
    }
})
export const { updateSearchFilter, resetSearchFilter } = packageSlice.actions;

export default packageSlice.reducer