import { ApiConfig } from "./apiConfig";

export const get_dashboard_datas = async (data) => {
  try {
    const response = await ApiConfig.post(`dashboard`, data, { headers: { "Content-Type": "multipart/form-data" } });
    return response;
  } catch (error) {
    throw error;
  }
};

export const getServicesByStatus = async (data) => {
  try {
    const response = await ApiConfig.post(`getServicesByStatus`, data, { headers: { "Content-Type": "multipart/form-data" } });
    return response;
  } catch (error) {
    throw error;
  }
};