import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import CustomTextField from '../../../../../components/custom_text_field/CustomTextField';
import CustomAutoCompleteDropdown from '../../../../../components/custom_auto_complete_dropdown/CustomAutoCompleteDropdown';
import './flightTicketTableFilterStyle.css'
import { get_ecr_status, get_ticket_type, get_trip_type } from '../../../../../api/commonLookUpApis';
import { get_supplier_without_pagination } from '../../../../../api/supplierApis';
import { get_client_lists_without_pagination } from '../../../../../api/clientApis';
import CustomDatePicker from '../../../../../components/custom_date_picker/CustomDatePicker';
import dayjs from "dayjs";
import CustomDateRangePicker from '../../../../../components/custom_date_range_picker/CustomDateRangePicker';
import { useDispatch, useSelector } from 'react-redux';
import { updateSearchFilter } from '../../../../../store/process_ecr/flightTicketStore';
import { RiFilterFill } from "react-icons/ri";
function FlightTicketTableFilter({ searchFilter }) {
    const ecr_filter = useSelector((state) => state.flightTicketStore.ecr_filter)
    const filter_data_store=useSelector((state)=>state.commonStore.notification_redirect)
    const [advanceFilter, setAdvanceFilter] = useState(false)
    const [ticketType, setTicketType] = useState([])
    const [tripType, setTripType] = useState([])
    const [ecrStatus, setEcrStatus] = useState([])
    const dispatch = useDispatch()
    useEffect(() => {
        getTicketTypeList()
        getTripTypeList()
        getEcrStatus()
        formik.setValues({ ...formik.values, ...ecr_filter })
    }, [filter_data_store,ecr_filter])
    const formik = useFormik({
        initialValues: {
            'FBD_PNR': '',
            "FBD_ticket_type_id": '',
            "FBD_trip_type": '',
            'FBD_supplier_id': '',
            'FBD_client_id': '',
            'FBD_issue_start_date': '',
            'FBD_issue_end_date': '',
            'FBD_ECRstatusID': ''
        },
        onSubmit: (values) => {
            dispatch(updateSearchFilter({ ...values }))
            searchFilter(values);
        }
    });
    const getTicketTypeList = () => {
        get_ticket_type().then((response) => {
            const data = response?.data?.data?.map((item) => {
                return {
                    label: item.TT_type,
                    value: item.TT_id,
                };
            });
            setTicketType(data)
        }).catch((error) => {
            console.log(error)
        })
    }

    const getTripTypeList = () => {
        get_trip_type().then((response) => {
            const data = response?.data?.data?.map((item) => {
                return {
                    label: item.TRIP_name,
                    value: item.TRIP_id,
                };
            });
            setTripType(data)
        }).catch((error) => {
            console.log(error)
        })
    }

    const getEcrStatus = () => {
        get_ecr_status().then((response) => {
            const data = response?.data?.data?.map((item) => {
                return {
                    label: item.ESL_name,
                    value: item.ESL_id,
                };
            });
            setEcrStatus(data)
        }).catch((error) => {
            console.log(error)
        })
    }
    const resetFilter = () => {
        formik.setValues({
            ...formik.values, ...{
                'FBD_PNR': '',
                "FBD_ticket_type_id": '',
                "FBD_trip_type": '',
                'FBD_supplier_id': '',
                'FBD_client_id': '',
                'FBD_issue_start_date': '',
                'FBD_issue_end_date': '',
                'FBD_ECRstatusID': ''
            }
        })
        searchFilter(null)
    }
    return (
        <div className='flight_ticket_filter_main_container'>
            <form id="flightTicketFilterform" onSubmit={formik.handleSubmit}>
                <div className='flight_ticket_filter_sub_container'>
                    <div className='flight_ticket_filter_style'>
                        <CustomTextField
                            name="FBD_PNR"
                            placeholder={"Enter pnr number"}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.FBD_PNR}
                        />
                    </div>
                    <div className='flight_ticket_filter_style'>
                        <CustomAutoCompleteDropdown
                            name="FBD_ticket_type_id"
                            placeholder={"Select a ticket type"}
                            options={ticketType}
                            onChange={(event, newValue) => {
                                formik.setFieldValue(
                                    "FBD_ticket_type_id",
                                    newValue ? newValue.value : ""
                                );
                            }}
                            onBlur={formik.handleBlur}
                            value={
                                ticketType.find((type) =>
                                    type.value ===
                                    formik.values.FBD_ticket_type_id
                                ) || null
                            }
                        />
                    </div>
                    <div className='flight_ticket_filter_style'>
                        <CustomAutoCompleteDropdown
                            name="FBD_trip_type"
                            placeholder={"Select a trip type"}
                            options={tripType}
                            onChange={(event, newValue) => {
                                formik.setFieldValue(
                                    "FBD_trip_type",
                                    newValue ? newValue.value : ""
                                );
                            }}
                            onBlur={formik.handleBlur}
                            value={
                                tripType.find((type) =>
                                    type.value ===
                                    formik.values.FBD_trip_type
                                ) || null
                            }
                        />
                    </div>
                    <div className='flight_ticket_filter_style'>
                        <CustomAutoCompleteDropdown
                            name="FBD_ECRstatusID"
                            placeholder={"Select a status"}
                            options={ecrStatus}
                            onChange={(event, newValue) => {
                                formik.setFieldValue(
                                    "FBD_ECRstatusID",
                                    newValue ? newValue.value : ""
                                );
                            }}
                            onBlur={formik.handleBlur}
                            value={
                                ecrStatus.find((type) =>
                                    type.value ===
                                    formik.values.FBD_ECRstatusID
                                ) || null
                            }
                        />
                    </div>
                    <div className='flight_ticket_filter_actions'>
                        <button className='flight_ticket_filter_action_reset' onClick={() => {
                            resetFilter()
                        }}>Reset</button>
                        <button className='flight_ticket_filter_action_search'
                            type="submit"
                            form="flightTicketFilterform"
                        >Search</button>
                    </div>

                    <RiFilterFill className='flight_ticket_filter_action_icons' onClick={() => {
                        setAdvanceFilter(!advanceFilter)
                    }} />

                </div>
                {advanceFilter && (
                    <div className='flight_ticket_filter_sub_container'>
                       
                        <div className='flight_ticket_filter_style_1'>
                            <CustomTextField
                                name="FBD_supplier_id"
                                placeholder={"Enter supplier"}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.FBD_supplier_id}
                            />
                        </div>

                        <div className='flight_ticket_filter_style_1'>
                            <CustomTextField
                                name="FBD_client_id"
                                placeholder={"Enter client"}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.FBD_client_id}
                            />
                        </div>

                        <div className='flight_ticket_filter_sub_date_selection_container'>
                            <div className='flight_ticket_filter_style_1'>
                                <p className='flight_ticket_filter_legent_style_1'>Issue start to end date</p>
                                <CustomDatePicker
                                    no_border
                                    name="FBD_issue_start_date"
                                    onChange={(date) => {
                                        formik.setFieldValue("FBD_issue_start_date", date);
                                    }}
                                    onBlur={formik.handleBlur}
                                    value={dayjs(formik.values.FBD_issue_start_date, "DD-MM-YYYY")}
                                    error={
                                        formik.touched.FBD_issue_start_date &&
                                        Boolean(formik.errors.FBD_issue_start_date)
                                    }
                                    helperText={
                                        formik.touched.FBD_issue_start_date && formik.errors.FBD_issue_start_date
                                    } />
                            </div>
                            <div>
                                <p>--</p>
                            </div>
                            <div className='flight_ticket_filter_style_1'>
                                {/* <p className='flight_ticket_filter_legent_style_2'>End date</p> */}
                                <CustomDatePicker
                                    no_border
                                    disabled={formik.values.FBD_issue_start_date?false:true}
                                    minDate={dayjs(formik.values.FBD_issue_start_date, "DD-MM-YYYY")}
                                    name="FBD_issue_end_date"
                                    onChange={(date) => {
                                        formik.setFieldValue("FBD_issue_end_date", date);
                                    }}
                                    onBlur={formik.handleBlur}
                                    value={dayjs(formik.values.FBD_issue_end_date, "DD-MM-YYYY")}
                                    error={
                                        formik.touched.FBD_issue_end_date &&
                                        Boolean(formik.errors.FBD_issue_end_date)
                                    }
                                    helperText={
                                        formik.touched.FBD_issue_end_date && formik.errors.FBD_issue_end_date
                                    } />
                            </div>
                        </div>
                    </div>
                )}
            </form>
        </div>
    )
}

export default FlightTicketTableFilter